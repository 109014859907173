/**
=======================================================================
Nuxt Plugin for Email Sending
=======================================================================
This Nuxt plugin provides an email sending utility using Axios for HTTP
requests. It integrates with the `useEmail` composable to manage the 
status of email sending operations and supports attachments.
*/

import axios from 'axios'; // Import axios for HTTP requests
import { useEmail } from '~/composables/useEmail'; // Import the useEmail composable

export default defineNuxtPlugin(() => {
  // Access the status and updateStatus methods from the useEmail composable
  const { status, updateStatus } = useEmail();

  async function sendEmail({ from, to, subject, plain, html, attachments = [] }) {
    try {
      // Set loading state to true
      updateStatus({ isLoading: true, success: false, error: null });

      // Make the POST request with email payload
      const response = await axios.post(
        'https://gateway.cloudrestfulapi.com/email/send',
        {
          from,
          to,
          subject,
          plain,
          html,
          attachments, // Include attachments in the payload
        },
        {
          headers: {
            'Content-Type': 'application/json', // Set the content type
          }
        }
      );

      // Update status to success
      updateStatus({ isLoading: false, success: true });
      return response.data; // Return API response data
    } catch (err) {
      // Update error state and log the error
      updateStatus({ isLoading: false, error: err });
      console.error('Error sending email:', err);
      throw new Error('Failed to send email'); // Rethrow the error
    }
  }

  // Provide the sendEmail function for global use
  return {
    provide: {
      sendEmail,
    },
  };
});

/* 
=======================================================================
How to Use
=======================================================================

To use the `$sendEmail` function, you can call it within any component 
or composable after accessing it from the Nuxt app instance.

Example:

const { $sendEmail } = useNuxtApp();

await $sendEmail({
  from: 'Sender Name <noreply@cloud-service.email>', // Sender email address
  to: ['Recipient Name <recipient@example.com>'], // Recipient email address
  subject: 'Email Subject', // Email subject
  plain: 'This is the plain text content of the email.', // Plain text email body
  html: '<p>This is the HTML content of the email.</p>', // HTML email body
  data: { name: 'Recipient Name', message: 'This is the message content.' }, // Additional data
});

*/
