<template>
    <div>
      <!-- <div>
        <p>Last route: {{ lastRoute }}</p>
        <p>Current route: {{ currentRoute }}</p>
      </div> -->
      <component :is="loadedComponent" v-if="loadedComponent" />
      <div v-else>Loading content...</div>
    </div>
  </template>
  
  <script setup>
  import { shallowRef, ref, watch, onMounted } from 'vue';
  import { useAuth } from '~/composables/useAuth'; // Get User role for make Get COntent by Role
  import { useHistory } from '~/composables/useHistory';
  
  // History composable for route data
  const { lastRoute, currentRoute } = useHistory();
  
  // Auth composable for decoding token
  const { decodeToken } = useAuth();
  const decodedToken = ref(null); // Ref to store decoded token
  
  const props = defineProps({
    path: {
      type: String,
      required: true
    },
    page: {
      type: String,
      required: true
    }
  });
  
  const role = ref(props.role); // Define role as a ref so it can be updated dynamically
  const loadedComponent = shallowRef(null);
  
  // Function to dynamically import component based on role and path
  const loadComponent = async (page, path, role) => {
    try {
      loadedComponent.value = (await import(`~/pages/${page}/_component/${path}/${role}/index.vue`)).default;
    } catch (error) {
      console.error(`Failed to load component for role: ${role} and path: ${path}`, error);
    }
  };
  
  // Function to decode the token and update role
  const fetchDecodedToken = async () => {
    try {
      const tokenData = await decodeToken();
      decodedToken.value = tokenData;
      role.value = tokenData.role; // Update the role based on the decoded token
      loadComponent(props.page, props.path, role.value); // Load component based on the new role
    } catch (error) {
      console.error('Failed to decode token', error);
    }
  };
  
  // Load component when the component mounts or when props change
  onMounted(() => fetchDecodedToken());
  
  watch([() => props.path, () => role.value], ([newPath, newRole]) => {
    loadComponent(props.page, newPath, newRole);
  });
  </script>
  
  <style scoped>
  /* Add any specific styles here */
  </style>
  