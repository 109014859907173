// position.js

// Function to handle position property
export function position(value) {
    const positionMap = {
      'static': 'static',
      'relative': 'relative',
      'absolute': 'absolute',
      'fixed': 'fixed',
      'sticky': 'sticky'
    };
    
    return positionMap[value] || 'relative'; // Default to 'relative' if the value is not found
  }
  