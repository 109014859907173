export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('stack', {
      mounted(el, binding) {
        const { 
          mode = 'stack', 
          layers = 3, 
          offset = 10, 
          layerColors = [], 
          cardOptions = {}, 
          wrapperScale = 0.8, 
          borderWidth = 10 
        } = binding.value || {};
    
        const style = document.createElement('style');
        style.innerHTML = `
          .wrapper {
            position: relative;
            display: inline-block;
            width: fit-content;
            height: fit-content;
            transform: scale(${wrapperScale});
            transform-origin: center center;
          }
  
          .stack-layer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
            transition: transform 0.2s ease, filter 0.2s ease;
            transform-origin: center center;
            z-index: 1;
            border: ${borderWidth}px solid #fff;
          }
  
          .stack-layer .image-container {
            width: 100%;
            height: 100%;
            filter: brightness(80%);
          }
  
          .main-image {
            z-index: 999;
            position: relative;
            border: ${borderWidth}px solid #fff;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          }
  
          .card {
            background-color: white;
            padding: ${cardOptions.padding || '20px'};
            border-radius: ${cardOptions.borderRadius || '10px'};
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
          }
  
          .button-center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: rgba(0, 0, 0, 0.6);
            color: white;
            padding: 10px 20px;
            border-radius: 5px;
            z-index: 999999;
            transition: opacity 0.3s ease;
            pointer-events: none;
          }
  
          .wrapper:hover .button-center {
            opacity: 1;
            pointer-events: all;
          }
        `;
        document.head.appendChild(style);
    
        const wrapper = document.createElement('div');
        wrapper.classList.add('wrapper');
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
    
        // Create stack layers
        for (let i = 0; i < layers; i++) {
          const stackLayer = document.createElement('div'); 
          stackLayer.classList.add('stack-layer');
  
          const imageContainer = document.createElement('div');
          imageContainer.classList.add('image-container');
    
          const clonedImage = el.cloneNode(true);
          imageContainer.appendChild(clonedImage);
    
          stackLayer.appendChild(imageContainer);
  
          // Remove top and left positioning logic
          // stackLayer.style.top = `${i * offset}px`; // Removed
          // stackLayer.style.left = `${i * offset}px`; // Removed
  
          // Generate a random rotation angle between -10 and 10 degrees
          const rotationAngle = Math.random() * 20 - 10;
          stackLayer.style.transform = `rotate(${rotationAngle}deg)`;
  
          wrapper.insertBefore(stackLayer, el);
        }
    
        el.classList.add('main-image');
    
        if (mode === 'card') {
          wrapper.classList.add('card');
        }
        
        // Create and add button
        const button = document.createElement('button');
        button.classList.add('button-center');
        button.textContent = 'Click to View';
        wrapper.appendChild(button);
      },
    });
  });
  