import axios from 'axios'; // Import axios for making HTTP requests
import { ref } from 'vue'; // Import ref for reactive state management

export default defineNuxtPlugin(() => {
  const apiUrl = 'https://gateway.cloudrestfulapi.com/account/wallet'; // Full URL for the wallet API
  const apiKey = 'DU1eYMDG7j8yb199YDPg3'; // Example API key

  // State variables for caching
  const balance = ref(null);
  const transactions = ref([]);
  const isLoading = ref(false);
  const error = ref(null);

  // Helper function to get auth token
  const getAuthToken = () => {
    const authToken = useCookie('_account'); // Fetch authToken from the cookie
    const token = authToken.value;
    if (!token) {
      throw new Error('Authentication token is missing');
    }
    return token;
  };

  const walletService = {
    // Fetch wallet data including balance and recent transactions
    async getWallet() {
      try {
        isLoading.value = true;
        const token = getAuthToken(); // Fetch the auth token

        // Use axios to make the POST request for fetching wallet data
        const response = await axios.post(`${apiUrl}?key=${apiKey}`, 
          { mode: 'get' }, 
          { headers: { Authorization: `${token}` } } // Pass token in Authorization header
        );

        if (response.data?.balance !== undefined && Array.isArray(response.data.transactions)) {
          balance.value = response.data.balance; // Cache balance locally
          transactions.value = response.data.transactions; // Cache transactions locally
          error.value = null; // Clear any previous errors
          return response.data; // Return wallet data (balance and transactions)
        } else {
          throw new Error('Invalid response format from server');
        }
      } catch (err) {
        logError('getWallet', err);
        error.value = err.message || 'Error fetching wallet data';
        throw err;
      } finally {
        isLoading.value = false;
      }
    },

    // Update the wallet balance (increase, decrease, or adjust)
    async updateWalletBalance(mode, amount) {
      try {
        const token = getAuthToken(); // Fetch the auth token

        // Use axios to make the POST request for updating the wallet balance
        const response = await axios.post(`${apiUrl}?key=${apiKey}`, 
          { mode, amount }, 
          { headers: { Authorization: `${token}` } } // Pass token in Authorization header
        );

        if (response.data?.balance !== undefined) {
          balance.value = response.data.balance; // Update cached balance
          error.value = null; // Clear any previous errors
          return balance.value;
        } else {
          throw new Error('Invalid response format from server');
        }
      } catch (err) {
        logError('updateWalletBalance', err, { mode, amount });
        error.value = err.message || 'Error updating wallet balance';
        throw err;
      }
    },

    // Convenience methods for updating balance with specific modes
    async increaseWallet(amount) {
      return this.updateWalletBalance('increase', amount);
    },

    async decreaseWallet(amount) {
      return this.updateWalletBalance('decrease', amount);
    },

    async adjustWallet(amount) {
      return this.updateWalletBalance('adjust', amount);
    },
  };

  // Function to log errors with detailed information
  const logError = (functionName, error, requestData = {}) => {
    //console.error(`Error in ${functionName}: ${error.message}`);
    if (requestData) {
      //console.error('Request Data:', requestData);
    }
  };

  // Inject the walletService into the Nuxt app context
  return {
    provide: {
      walletService, // Provide the walletService globally
    },
  };
});
