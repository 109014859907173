export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('gallery', {
      mounted(el, binding) {
        const images = binding.value || [];
        let currentIndex = 0;
  
        if (!Array.isArray(images) || images.length === 0) return;
  
        // Create a gallery container that will use a CSS grid
        const galleryContainer = document.createElement('div');
        galleryContainer.style.display = 'grid';
        galleryContainer.style.gridTemplateColumns = 'repeat(auto-fill, minmax(200px, 1fr))'; // Responsive columns
        galleryContainer.style.gridAutoRows = '10px'; // Defines row heights
        galleryContainer.style.gap = '10px'; // Spacing between images
        galleryContainer.style.alignItems = 'stretch'; // Stretch images to fill grid spaces
  
        // Create lightbox modal
        const lightboxOverlay = document.createElement('div');
        lightboxOverlay.style.position = 'fixed';
        lightboxOverlay.style.top = '0';
        lightboxOverlay.style.left = '0';
        lightboxOverlay.style.width = '100%';
        lightboxOverlay.style.height = '100%';
        lightboxOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
        lightboxOverlay.style.display = 'none'; // Hidden initially
        lightboxOverlay.style.alignItems = 'center';
        lightboxOverlay.style.justifyContent = 'center';
        lightboxOverlay.style.flexDirection = 'column'; // Ensure vertical layout
        lightboxOverlay.style.zIndex = '1000';
  
        // Create navigation buttons
        const prevButton = document.createElement('button');
        prevButton.innerHTML = 'Previous';
        prevButton.style.position = 'absolute';
        prevButton.style.left = '10px';
        prevButton.style.top = '50%';
        prevButton.style.transform = 'translateY(-50%)';
        prevButton.style.backgroundColor = 'rgba(255, 255, 255, 0.7)';
        prevButton.style.border = 'none';
        prevButton.style.padding = '10px';
        prevButton.style.cursor = 'pointer';
  
        const nextButton = document.createElement('button');
        nextButton.innerHTML = 'Next';
        nextButton.style.position = 'absolute';
        nextButton.style.right = '10px';
        nextButton.style.top = '50%';
        nextButton.style.transform = 'translateY(-50%)';
        nextButton.style.backgroundColor = 'rgba(255, 255, 255, 0.7)';
        nextButton.style.border = 'none';
        nextButton.style.padding = '10px';
        nextButton.style.cursor = 'pointer';
  
        const fullImage = document.createElement('img');
        fullImage.style.maxWidth = '90%';
        fullImage.style.maxHeight = '90%';
        fullImage.style.display = 'block'; // Ensure image is block-level
  
        const captionText = document.createElement('p');
        captionText.style.color = '#fff';
        captionText.style.textAlign = 'center';
        captionText.style.marginTop = '10px'; // Add space between image and caption
  
        lightboxOverlay.appendChild(prevButton);
        lightboxOverlay.appendChild(fullImage);
        lightboxOverlay.appendChild(captionText);
        lightboxOverlay.appendChild(nextButton);
  
        // Close the lightbox when clicked outside the image
        lightboxOverlay.addEventListener('click', (e) => {
          if (e.target === lightboxOverlay) {
            lightboxOverlay.style.display = 'none';
          }
        });
  
        document.body.appendChild(lightboxOverlay); // Append the lightbox to the document body
  
        // Function to show the full image in the lightbox
        const showImage = (index) => {
          const imageData = images[index];
          fullImage.src = imageData.full || imageData.thumbnail; // Use thumbnail if full image is not provided
          captionText.innerText = imageData.caption || '';
          lightboxOverlay.style.display = 'flex';
        };
  
        // Function to handle next image navigation
        const nextImage = () => {
          currentIndex = (currentIndex + 1) % images.length; // Loop back to the first image
          showImage(currentIndex);
        };
  
        // Function to handle previous image navigation
        const prevImage = () => {
          currentIndex = (currentIndex - 1 + images.length) % images.length; // Loop back to the last image
          showImage(currentIndex);
        };
  
        nextButton.addEventListener('click', (e) => {
          e.stopPropagation(); // Prevent click from closing the lightbox
          nextImage();
        });
  
        prevButton.addEventListener('click', (e) => {
          e.stopPropagation(); // Prevent click from closing the lightbox
          prevImage();
        });
  
        // Function to create a thumbnail and handle click event
        const createThumbnail = (imageData, index) => {
          const thumbnailContainer = document.createElement('div');
          thumbnailContainer.style.cursor = 'pointer';
          thumbnailContainer.style.position = 'relative'; // Position the thumbnails correctly in the grid
          thumbnailContainer.style.overflow = 'hidden';
  
          const thumbnail = document.createElement('img');
          thumbnail.src = imageData.thumbnail;
          thumbnail.alt = imageData.caption || 'Gallery Image';
          thumbnail.style.width = '100%'; // Make sure the thumbnail fills the grid width
          thumbnail.style.height = 'auto'; // Maintain aspect ratio
  
          // Wait for the image to load to calculate its actual height
          thumbnail.onload = () => {
            const rowHeight = 10; // Base row height set in the grid
            const heightRatio = thumbnail.naturalHeight / thumbnail.naturalWidth; // Calculate the image's aspect ratio
            thumbnailContainer.style.gridRowEnd = `span ${Math.ceil(heightRatio * rowHeight)}`;
          };
  
          thumbnailContainer.appendChild(thumbnail);
  
          // Click event to show the full image in the lightbox
          thumbnailContainer.addEventListener('click', () => {
            currentIndex = index; // Set current index to the clicked image
            showImage(currentIndex);
          });
  
          return thumbnailContainer;
        };
  
        // Create and append thumbnails to the gallery container
        images.forEach((imageData, index) => {
          const thumbnailElement = createThumbnail(imageData, index);
          galleryContainer.appendChild(thumbnailElement);
        });
  
        // Append the gallery container to the directive element
        el.appendChild(galleryContainer);
      },
      updated(el, binding) {
        // Update the gallery if the binding value changes
        const images = binding.value || [];
        const galleryContainer = el.querySelector('div');
  
        if (!Array.isArray(images) || images.length === 0) return;
  
        // Clear previous thumbnails
        galleryContainer.innerHTML = '';
  
        // Create and append updated thumbnails
        images.forEach((imageData, index) => {
          const thumbnailElement = createThumbnail(imageData, index);
          galleryContainer.appendChild(thumbnailElement);
        });
      }
    });
  });
  