export default defineNuxtPlugin(async (nuxtApp) => {
  // Get the User-Agent
  const userAgent = process.server
    ? nuxtApp.ssrContext?.req?.headers['user-agent'] || ''
    : navigator.userAgent;

  // Check if 'LIFF' exists in the User-Agent
  const isLiff = userAgent.includes('LIFF');

  // Inject both userAgent and isLiff into the app context and Vue instances
  nuxtApp.provide('userAgent', userAgent);
  nuxtApp.provide('isLiff', isLiff);
});
