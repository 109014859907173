// background.js

// Convert background object to corresponding CSS values
export function background(bgObject, type = 'out') {
    if (typeof bgObject !== 'object' || !bgObject[type]) {
      return {}; // Return empty object if the input is not valid
    }
  
    const bg = bgObject[type];
    const styles = {};
  
    if (bg.type === 'color' && bg.color) {
      styles.backgroundColor = bg.color;
    } else if (bg.type === 'image' && bg.image) {
      styles.backgroundImage = `url(${bg.image})`;
      styles.backgroundSize = 'cover';
      styles.backgroundPosition = 'center';
    } else if (bg.type === 'gradient' && bg.gradientColor1 && bg.gradientColor2) {
      styles.backgroundImage = `linear-gradient(to right, ${bg.gradientColor1}, ${bg.gradientColor2})`;
    }
  
    return styles;
  }
  