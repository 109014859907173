import colorMap from './colorMap.js'; // Import the color map

// Helper function to convert Tailwind color to CSS color
const tailwindToCssColor = (tailwindColor) => {
  return colorMap[tailwindColor] || tailwindColor; // Use the color map or fallback to the provided value
};

// Helper function to validate and return a valid CSS size value
// Return '0' for zero values, and append 'px' for other numeric values if no unit is provided
const validateCssSize = (value) => {
    if (value === '0') return '0'; // Explicitly return '0' without units
    // Check if value is a number and convert it to a string with 'px'
    if (typeof value === 'number') return `${value}px`;
    // Check if value is a string containing a number without units, append 'px'
    if (typeof value === 'string' && value.match(/^[0-9]+$/)) return `${value}px`;
    // If the value is already a valid CSS size (contains units), return as is
    if (typeof value === 'string' && value.match(/^[0-9]+(px|em|rem|%)$/)) return value;
    // Fallback for invalid values
    return '0';
  };
  
  // Function to handle border width (returns value like '0 4px 8px 12px' or an empty string if all are '0')
  const borderWidth = (borderConfig = {}) => {
    const {
      top = '0',    // Default value is '0'
      right = '0',
      bottom = '0',
      left = '0'
    } = borderConfig;
  
    // Validate each value to ensure it's a valid CSS size or '0'
    const topWidth = validateCssSize(top); // Ensure '0' is returned if undefined or invalid
    const rightWidth = validateCssSize(right);
    const bottomWidth = validateCssSize(bottom);
    const leftWidth = validateCssSize(left);
  
    // Check if all sides are '0'
    if (topWidth === '0' && rightWidth === '0' && bottomWidth === '0' && leftWidth === '0') {
      return ''; // Return empty string to exclude 'border-width' from the style
    }
  
    // Return the full shorthand value if at least one side is non-zero
    return `${topWidth} ${rightWidth} ${bottomWidth} ${leftWidth}`;
  };
  
  
  

// Function to handle border style (returns value like 'solid')
const borderStyle = (borderConfig = {}) => {
  const validStyles = ['none', 'solid', 'dashed', 'dotted', 'double', 'groove', 'ridge', 'inset', 'outset'];
  const { style = 'solid' } = borderConfig;

  // Validate if the style is within the valid styles list
  return validStyles.includes(style) ? style : 'solid';
};

// Function to handle border color (returns value like 'rgb(209, 213, 219)')
const borderColor = (borderConfig = {}) => {
  const { color = 'transparent' } = borderConfig;

  // Validate if the color is a string
  return typeof color === 'string' ? tailwindToCssColor(color) : 'transparent';
};

// Function to handle border radius (returns value like '5px 6px 7px 8px')
const borderRadius = (borderConfig = {}) => {
  const {
    radius: {
      topLeft = '0',
      topRight = '0',
      bottomRight = '0',
      bottomLeft = '0'
    } = {}
  } = borderConfig;

  // Validate each value to ensure it's a valid CSS size
  const topLeftRadius = validateCssSize(topLeft);
  const topRightRadius = validateCssSize(topRight);
  const bottomRightRadius = validateCssSize(bottomRight);
  const bottomLeftRadius = validateCssSize(bottomLeft);

  return `${topLeftRadius} ${topRightRadius} ${bottomRightRadius} ${bottomLeftRadius}`;
};

// Main border object with methods for width, style, color, and radius

const border = {
  width: borderWidth,
  style: borderStyle,
  color: borderColor,
  radius: borderRadius,
  // Function to handle full border (returns full border shorthand value like '1px solid rgb(209, 213, 219)')
  full: function(borderConfig = {}) {
    const width = this.width(borderConfig);
    const style = this.style(borderConfig);
    const color = this.color(borderConfig);
    return `${width} ${style} ${color}`;
  }
};

// Export the border object
export default border;
