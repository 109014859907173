<template>
  <div :class="['frame-wrapper relative flex-1 h-full', backgroundClass]">
    <Loader :show="!hydrated || !status" :text="loadingText" />
    <div v-if="hydrated" class="mx-auto" :class="widthClass">
      <div :class="['frame-inner', innerBackgroundClass, paddingClass, marginClass]">
        <slot></slot>
      </div>
    </div>
    <ScrollToTop />
  </div>
</template>

<script setup>
import { ref, onMounted, computed, nextTick, watchEffect } from 'vue';
import { useRender } from '~/composables/useRender';
import Loader from './loader.vue';
import ScrollToTop from './scrolltotop.vue';
import eventBus from '~/eventBus';

const { status } = useRender();
const hydrated = ref(false);

onMounted(async () => {
  hydrated.value = true;

  // Ensure parent listener is registered first
  await nextTick(); // Delay emission until the next DOM update cycle
  eventBus.emit('updateBackground', `bg-${props.background}`);
});

const props = defineProps({
  background: {
    type: String,
    default: 'gray-100', // Default background
  },
  width: {
    type: String,
    default: '7xl', // Default width class
  },
  prefix: {
    type: String,
    default: 'Loading...', // Default loading text
  },
  innerBackground: {
    type: String,
    default: '', // Default inner background
  },
  padding: {
    type: String,
    default: 'p-0', // Default padding class
  },
  margin: {
    type: String,
    default: 'm-0', // Default margin class
  },
});

const backgroundClass = computed(() => `bg-${props.background}`);
const widthClass = computed(() => `max-w-${props.width}`);
const loadingText = computed(() => props.prefix || 'Loading...');
const innerBackgroundClass = computed(() => `bg-${props.innerBackground}`);
const paddingClass = computed(() => props.padding);
const marginClass = computed(() => props.margin);

watchEffect(() => {
  const newBackgroundClass = `bg-${props.background}`;
  eventBus.emit('updateBackground', newBackgroundClass);
});
</script>

<style scoped>
.min-h-screen {
  min-height: 100vh;
}

</style>
