export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('placeholder', {
    mounted(el, binding) {
      // Inject CSS into the document head
      const style = document.createElement('style');
      style.innerHTML = `
        /* Add the overlay text using a pseudo-element */
        .placeholder-container {
          position: relative;
          width: 100%;
          height: 100%;
          display: inline-block;
          overflow:hidden;
        }

        .placeholder-container.full-size {
          width: 100%; /* Full width */
          height: 100%; /* Full height */
        }

        .placeholder-container.block::after {
          content: 'Click to view';
          position: absolute;
          color: white;
          font-size: 18px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: rgba(0, 0, 0, 0.5); /* Optional background for contrast */
          padding: 5px 10px;
          border-radius: 5px;
          pointer-events: none; /* Ensure the text doesn't interfere with clicks */
          z-index: 10;
        }

        /* Style for the image to be responsive */
        .placeholder-image {
          width: 100%;
          height: auto;
        }
      `;
      document.head.appendChild(style);

      const {
        watermark = {},
        placeholderSrc = '',  // The src of the image is passed in the directive binding
        block = false,
      } = binding.value || {};

      const {
        type = 'text',
        value = 'Watermark',
        position = 'bottom-right',
        imageUrl = '',
      } = watermark;

      // Create a wrapper div around the image
      const wrapper = document.createElement('div');
      wrapper.classList.add('placeholder-container'); // Apply the container class

      if (block) {
        wrapper.classList.add('block'); // Show "Click" text if block = true
      }

      // Make the container full size (full width and height)
      wrapper.classList.add('full-size');

      // Move the image (el) inside the wrapper
      el.parentNode.insertBefore(wrapper, el);
      wrapper.appendChild(el);

      // Initially hide the image by setting display to 'none'
      el.style.display = 'none';

      // Function to fetch the remote image and get its size using a Blob
      const fetchImageSize = async (src) => {
        try {
          const response = await fetch(src, { method: 'GET' });
          const blob = await response.blob();

          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
              resolve({
                imgElement: img,  // Pass the image element for drawImage
                width: img.naturalWidth,
                height: img.naturalHeight,
              });
            };
            img.onerror = (err) => reject(err);
            img.src = URL.createObjectURL(blob); // Create a URL for the Blob
          });
        } catch (err) {
          console.error('Error fetching image:', err);
          return null;
        }
      };

      // Load the image size, then add watermark and handle graybox
      const processImage = async () => {
        try {
          // Fetch the image size and the image element using the remote URL
          const { imgElement, width, height } = await fetchImageSize(placeholderSrc);

          if (!width || !height) {
            console.error('Error: Image size could not be determined');
            return;
          }

          // Create a graybox placeholder with max-width and height:auto
          const grayPlaceholder = document.createElement('div');
          grayPlaceholder.style.position = 'relative';
          grayPlaceholder.style.maxWidth = '100%';  // Ensure the graybox follows img styles
          grayPlaceholder.style.height = 'auto';    // Make the height auto
          grayPlaceholder.style.aspectRatio = `${width} / ${height}`; // Maintain aspect ratio
          grayPlaceholder.style.backgroundColor = '#fff';
          grayPlaceholder.style.border = '1px solid #e3e3e3';
          grayPlaceholder.style.display = 'flex';
          grayPlaceholder.style.alignItems = 'center';
          grayPlaceholder.style.justifyContent = 'center';
          grayPlaceholder.style.flexDirection = 'column'; // Make content stacked vertically
          grayPlaceholder.style.color = '#555';
          grayPlaceholder.style.fontSize = '16px';

          // Create an image element for the loading icon
          const loadingIcon = document.createElement('img');
          loadingIcon.src = 'https://vue-project.sgp1.digitaloceanspaces.com/2024/10/1729644973739.gif'; // Replace with the URL of your loading icon
          loadingIcon.alt = 'Loading...';  // Provide alt text for accessibility
          loadingIcon.style.width = '150px';  // Set the width of the icon
          loadingIcon.style.height = '38px'; // Set the height of the icon

          // Create a text element for the "Loading..." text
          const loadingText = document.createElement('span');
          loadingText.innerText = 'โหลดไฟล์รูป...';  // Set the loading text
          loadingText.style.marginTop = '8px';   // Add some space between the icon and text

          // Append the loading icon and text to the gray placeholder
          grayPlaceholder.appendChild(loadingIcon);
          grayPlaceholder.appendChild(loadingText);

          // Insert the graybox above the hidden image
          el.parentNode.insertBefore(grayPlaceholder, el);

          // Create a canvas element to add the watermark
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = width;
          canvas.height = height;

          // Draw the original image on the canvas
          ctx.drawImage(imgElement, 0, 0);  // Use the image element fetched earlier

          // Add the watermark if needed
          if (type === 'text') {
            addTextWatermark(ctx, value, canvas, position);
          } else if (type === 'image' && imageUrl) {
            const { imgElement: watermarkImg } = await fetchImageSize(imageUrl);
            addImageWatermark(ctx, watermarkImg, canvas, position);
          }

          // Set the image to the canvas data URL (with the watermark)
          el.src = canvas.toDataURL();

          // Apply the same max-width and height:auto to the image
          el.style.maxWidth = '100%';
          el.style.height = 'auto';
          el.classList.add('placeholder-image'); // Add a class for the image for styling

          if (block) {
            el.style.filter = 'brightness(20%) contrast(100%) blur(1px)';
          }

          // Remove filter and the "click" text overlay on click
          el.addEventListener('click', () => {
            el.style.filter = ''; // Remove the filter when clicked
            wrapper.classList.remove('block'); // Remove the click overlay text
          });

          // Delay for 3 seconds before instantly hiding the graybox and showing the image
          setTimeout(() => {
            grayPlaceholder.style.display = 'none'; // Instantly hide the graybox
            el.style.display = 'block';  // Show the image
          }, 1000); // 3-second delay before hiding graybox and showing the image

        } catch (err) {
          console.error('Error processing image:', err);
        }
      };

      processImage();

      // Function to add a text watermark
      function addTextWatermark(ctx, watermarkText, canvas, position) {
        const dynamicFontSize = Math.floor(canvas.width / 20);
        const fontSize = `${dynamicFontSize}px`;
        const fontFamily = 'Arial';
        const color = 'rgba(255, 255, 255, 0.7)';
        const margin = Math.floor(canvas.width / 30);

        ctx.font = `${fontSize} ${fontFamily}`;
        ctx.fillStyle = color;

        const textWidth = ctx.measureText(watermarkText).width;

        let x, y;
        switch (position) {
          case 'top-left':
            x = margin;
            y = margin + dynamicFontSize;
            break;
          case 'top-right':
            x = canvas.width - textWidth - margin;
            y = margin + dynamicFontSize;
            break;
          case 'top-center':
            x = (canvas.width - textWidth) / 2;
            y = margin + dynamicFontSize;
            break;
          case 'bottom-left':
            x = margin;
            y = canvas.height - margin;
            break;
          case 'bottom-right':
            x = canvas.width - textWidth - margin;
            y = canvas.height - margin;
            break;
          case 'bottom-center':
            x = (canvas.width - textWidth) / 2;
            y = canvas.height - margin;
            break;
          case 'center':
            x = (canvas.width - textWidth) / 2;
            y = (canvas.height + dynamicFontSize) / 2;
            break;
          default:
            x = canvas.width - textWidth - margin;
            y = canvas.height - margin;
            break;
        }

        ctx.fillText(watermarkText, x, y);
      }

      // Function to add an image watermark
      function addImageWatermark(ctx, watermarkImage, canvas, position) {
        const watermarkWidth = canvas.width / 5; // Set watermark size relative to image size (20%)
        const watermarkHeight = (watermarkImage.height / watermarkImage.width) * watermarkWidth; // Keep aspect ratio
        const margin = Math.floor(canvas.width / 30);

        // Calculate watermark position
        let x, y;
        switch (position) {
          case 'top-left':
            x = margin;
            y = margin;
            break;
          case 'top-right':
            x = canvas.width - watermarkWidth - margin;
            y = margin;
            break;
          case 'top-center':
            x = (canvas.width - watermarkWidth) / 2;
            y = margin;
            break;
          case 'bottom-left':
            x = margin;
            y = canvas.height - watermarkHeight - margin;
            break;
          case 'bottom-right':
            x = canvas.width - watermarkWidth - margin;
            y = canvas.height - watermarkHeight - margin;
            break;
          case 'bottom-center':
            x = (canvas.width - watermarkWidth) / 2;
            y = canvas.height - watermarkHeight - margin;
            break;
          case 'center':
            x = (canvas.width - watermarkWidth) / 2;
            y = (canvas.height - watermarkHeight) / 2;
            break;
          default:
            x = canvas.width - watermarkWidth - margin;
            y = canvas.height - watermarkHeight - margin;
            break;
        }

        // Draw the watermark image
        ctx.drawImage(watermarkImage, x, y, watermarkWidth, watermarkHeight);
      }

      // Prevent right-click and show custom context menu
      el.addEventListener('contextmenu', (event) => {
        event.preventDefault(); // Prevent the default right-click menu

        // Create a custom context menu (for example, a copyright notice)
        const contextMenu = document.createElement('div');
        contextMenu.innerText = '© 2024 Your Company - All rights reserved.';
        contextMenu.style.position = 'absolute';
        contextMenu.style.backgroundColor = '#000';
        contextMenu.style.color = '#fff';
        contextMenu.style.padding = '10px';
        contextMenu.style.borderRadius = '5px';
        contextMenu.style.top = `${event.clientY}px`;
        contextMenu.style.left = `${event.clientX}px`;

        // Append the custom context menu to the body
        document.body.appendChild(contextMenu);

        // Remove the context menu when clicking anywhere else
        document.addEventListener('click', () => {
          contextMenu.remove();
        }, { once: true });
      });

    },
  });
});
