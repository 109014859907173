export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('ribbon', {
      mounted(el, binding) {
        // Extract options from binding value
        const { text = 'Ribbon', position = 'top-left', color = '#ff0000' } = binding.value || {};
  
        // Inject CSS styles into the document head
        const style = document.createElement('style');
        style.innerHTML = `
          .ribbon-wrapper {
            position: relative;
            display: inline-block;
            width: fit-content; /* Adjust based on the image size */
            overflow: hidden;
          }
  
          .ribbon {
            width: 150px;
            height: 30px;
            background-color: ${color};
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            letter-spacing: 2px;
            font-weight: bold;
            text-transform: uppercase;
            position: absolute;
            z-index: 10;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          }
  
          /* Positioning ribbons */
            .ribbon-top-left {
                top: 85px;
                left: -21px;
                transform: rotate(-45deg);
                transform-origin: 0 0;
            }
  
          .ribbon-top-right {
            top: 85px;
            right: -21px;
            transform: rotate(45deg); /* Make it diagonal */
            transform-origin: 100% 0; /* Start from the corner */
          }
  
          .ribbon-bottom-left {
            bottom: 85px;
            left: -21px;
            transform: rotate(45deg); /* Make it diagonal */
            transform-origin: 0 100%; /* Start from the corner */
          }
  
          .ribbon-bottom-right {
            bottom: 85px;
            right: -21px;
            transform: rotate(-45deg); /* Make it diagonal */
            transform-origin: 100% 100%; /* Start from the corner */
          }
        `;
        document.head.appendChild(style);
  
        // Create a wrapper div around the image
        const wrapper = document.createElement('div');
        wrapper.classList.add('ribbon-wrapper');
        el.parentNode.insertBefore(wrapper, el); // Insert wrapper before the image
        wrapper.appendChild(el); // Move image inside wrapper
  
        // Create the ribbon element
        const ribbon = document.createElement('div');
        ribbon.classList.add('ribbon', `ribbon-${position}`);
        ribbon.innerText = text;
  
        // Append the ribbon to the wrapper
        wrapper.appendChild(ribbon);
      },
    });
  });
  