<template>
    <div>
      <!-- Custom right-click menu -->
      <div
        v-if="isMenuVisible"
        :style="{ top: `${menuPosition.y}px`, left: `${menuPosition.x}px` }"
        class="context-menu"
      >
        <ul>
          <li class="copyright-text">© 2024 Your Company Name</li> <!-- Copyright text -->
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isMenuVisible: false,
        menuPosition: { x: 0, y: 0 },
      };
    },
    mounted() {
      document.addEventListener('contextmenu', this.showMenu);
      document.addEventListener('click', this.hideMenu);
    },
    beforeDestroy() {
      document.removeEventListener('contextmenu', this.showMenu);
      document.removeEventListener('click', this.hideMenu);
    },
    methods: {
      showMenu(event) {
        event.preventDefault();
        this.isMenuVisible = true;
        // Get mouse position relative to the viewport
        this.menuPosition = {
          x: event.clientX,
          y: event.clientY,
        };
      },
      hideMenu() {
        this.isMenuVisible = false;
      },
    },
  };
  </script>
  
  <style scoped>
  .context-menu {
    position: absolute;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  .context-menu ul {
    list-style: none;
    margin: 0;
    padding: 10px;
  }
  
  .context-menu li {
    padding: 8px 12px;
    font-size: 12px;
    text-align: center;
    cursor: default;
    background-color: transparent;
  }
  </style>
  