export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('divider', {
      mounted(el, binding) {
        const { value } = binding;
        const text = value?.text || '';
        const bgColor = value?.bgColor || '#1e3a8a'; // Default background color
        const textColor = value?.textColor || '#ffffff'; // Default text color white
        const lineColor = value?.lineColor || '#d1d5db'; // Default line color
        const lineHeight = value?.lineHeight || '1px'; // Line thickness
        const lineStyle = value?.lineStyle || 'solid'; // Default line style (solid, dotted, dashed)
        const padding = value?.padding || '5px 10px'; // Default padding for text container
        const borderRadius = value?.borderRadius || '4px'; // Border radius for the background
  
        // Create left line
        const leftLine = document.createElement('div');
        leftLine.style.flexGrow = '1'; // Allow line to grow and fill space
        leftLine.style.borderTop = `${lineHeight} ${lineStyle} ${lineColor}`;
        leftLine.style.marginRight = '10px'; // Adjust spacing as needed
  
        // Create right line
        const rightLine = document.createElement('div');
        rightLine.style.flexGrow = '1'; // Allow line to grow and fill space
        rightLine.style.borderTop = `${lineHeight} ${lineStyle} ${lineColor}`;
        rightLine.style.marginLeft = '10px'; // Adjust spacing as needed
  
        // Create center text container
        const textContainer = document.createElement('div');
        textContainer.innerText = text;
        textContainer.style.backgroundColor = bgColor; // Set background color
        textContainer.style.color = textColor; // Set text color
        textContainer.style.padding = padding; // Set padding
        textContainer.style.borderRadius = borderRadius; // Set border radius
        textContainer.style.whiteSpace = 'nowrap'; // Prevent the text from wrapping and taking up too much space
  
        // Create a container to hold the lines and text
        const container = document.createElement('div');
        container.style.display = 'flex';
        container.style.alignItems = 'center';
        container.style.justifyContent = 'center';
        container.style.width = '100%';
  
        // Append elements to the container
        container.appendChild(leftLine);
        container.appendChild(textContainer);
        container.appendChild(rightLine);
  
        // Append the container to the element where the directive is applied
        el.appendChild(container);
      },
      updated(el, binding) {
        // Update the text and styles if the directive value changes
        const { value } = binding;
        const text = value?.text || '';
        const bgColor = value?.bgColor || '#1e3a8a';
        const textColor = value?.textColor || '#ffffff';
        const lineColor = value?.lineColor || '#d1d5db';
        const lineHeight = value?.lineHeight || '1px';
        const lineStyle = value?.lineStyle || 'solid';
        const padding = value?.padding || '5px 10px';
        const borderRadius = value?.borderRadius || '4px';
  
        const textContainer = el.querySelector('div > div');
        const leftLine = el.querySelector('div > div:first-child');
        const rightLine = el.querySelector('div > div:last-child');
  
        textContainer.innerText = text;
        textContainer.style.backgroundColor = bgColor;
        textContainer.style.color = textColor;
        textContainer.style.padding = padding;
        textContainer.style.borderRadius = borderRadius;
  
        leftLine.style.borderTop = `${lineHeight} ${lineStyle} ${lineColor}`;
        rightLine.style.borderTop = `${lineHeight} ${lineStyle} ${lineColor}`;
      }
    });
  });
  