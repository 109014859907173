import { jwtVerify } from 'jose'; // Import the browser-compatible verification method

export default defineNuxtRouteMiddleware(async () => {
  const authToken = useCookie('_account');
  const secretKey = new TextEncoder().encode('ZCOKU1v3TO2flcOqCdrJ3vWbWhmnZNQn'); // Replace with your actual secret key

  if (!authToken.value) {
    // No auth token found
    return false;
  }

  try {
    // Verify and decode the token, ensuring HS256 is used
    const { payload, protectedHeader } = await jwtVerify(authToken.value, secretKey, {
      algorithms: ['HS256'], // Ensuring only HS256 is allowed
    });

    // Token is verified and valid
    return true;
  } catch (err) {
    // Handle verification errors
    return false;
  }
});

// Utility to remove the auth token (for logout)
export function removeAuthToken() {
  const authToken = useCookie('_account');
  authToken.value = null; // Set the cookie value to null, which will remove the cookie
}
