<template>
    <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="bg-white p-6 rounded-md shadow-md w-full max-w-lg">
        <h2 class="text-xl font-bold mb-4">{{ title }}</h2>
        <p class="text-gray-600 mb-6">{{ message }}</p>
        <div class="flex justify-end space-x-4">
          <button @click="handleConfirm" class="px-4 py-2 bg-blue-500 text-white rounded-md">
            {{ confirmButtonText }}
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  const props = defineProps({
    message: { type: String, required: true },
    title: { type: String, default: 'Alert' },
    confirmButtonText: { type: String, default: 'OK' },
  });
  
  const emit = defineEmits(['confirm']);
  
  const handleConfirm = () => {
    emit('confirm');
  };
  </script>
  