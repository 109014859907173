import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import idle_detection_8q9jkSEG2p from "/opt/buildhome/repo/plugins/idle-detection.js";
import loadingOverlay_client_JbKE6qaZKY from "/opt/buildhome/repo/plugins/loadingOverlay.client.js";
import wallet_LD2vT4ZoTs from "/opt/buildhome/repo/plugins/wallet.js";
import filemanager_BGvKdA3nY2 from "/opt/buildhome/repo/plugins/filemanager.js";
import ai_urlm3ADzQA from "/opt/buildhome/repo/plugins/ai.js";
import alertPrompt_IXUuIe1ka8 from "/opt/buildhome/repo/plugins/alertPrompt.js";
import button_yaGN6iSbiy from "/opt/buildhome/repo/plugins/button.js";
import divider_FJ34obs5Hx from "/opt/buildhome/repo/plugins/divider.js";
import email_NYtZ102NHN from "/opt/buildhome/repo/plugins/email.js";
import file_gzQX5IhY7i from "/opt/buildhome/repo/plugins/file.js";
import fontawesome_klhsrycjcK from "/opt/buildhome/repo/plugins/fontawesome.js";
import gallery_BpT9EgNkUA from "/opt/buildhome/repo/plugins/gallery.js";
import germini_LCse7tCSo1 from "/opt/buildhome/repo/plugins/germini.js";
import getClientData_RWRU0WWhxJ from "/opt/buildhome/repo/plugins/getClientData.js";
import global_components_6g9ZiColWR from "/opt/buildhome/repo/plugins/global-components.js";
import lightbox_sXglwHRuOt from "/opt/buildhome/repo/plugins/lightbox.js";
import placeholder_Ci12OmgccV from "/opt/buildhome/repo/plugins/placeholder.js";
import plausible_BNZtRDJXL7 from "/opt/buildhome/repo/plugins/plausible.js";
import readmore_9Au0sNzvwO from "/opt/buildhome/repo/plugins/readmore.js";
import ribbon_X7j86QnXva from "/opt/buildhome/repo/plugins/ribbon.js";
import scrollbar_a340khSom3 from "/opt/buildhome/repo/plugins/scrollbar.js";
import stack_CSNb2r4Pgz from "/opt/buildhome/repo/plugins/stack.js";
import styles_MV9qon0Moa from "/opt/buildhome/repo/plugins/styles.js";
import tooltip_07WqZjDZUO from "/opt/buildhome/repo/plugins/tooltip.js";
import userAgent_tqAakzBnpL from "/opt/buildhome/repo/plugins/userAgent.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  idle_detection_8q9jkSEG2p,
  loadingOverlay_client_JbKE6qaZKY,
  wallet_LD2vT4ZoTs,
  filemanager_BGvKdA3nY2,
  ai_urlm3ADzQA,
  alertPrompt_IXUuIe1ka8,
  button_yaGN6iSbiy,
  divider_FJ34obs5Hx,
  email_NYtZ102NHN,
  file_gzQX5IhY7i,
  fontawesome_klhsrycjcK,
  gallery_BpT9EgNkUA,
  germini_LCse7tCSo1,
  getClientData_RWRU0WWhxJ,
  global_components_6g9ZiColWR,
  lightbox_sXglwHRuOt,
  placeholder_Ci12OmgccV,
  plausible_BNZtRDJXL7,
  readmore_9Au0sNzvwO,
  ribbon_X7j86QnXva,
  scrollbar_a340khSom3,
  stack_CSNb2r4Pgz,
  styles_MV9qon0Moa,
  tooltip_07WqZjDZUO,
  userAgent_tqAakzBnpL
]