export default defineNuxtPlugin(() => {
  if (process.client) {
    const loadPlausibleScript = () => {
      const hostname = window.location.hostname; // Get the current hostname
      console.log("Hostname", hostname);

      // Remove existing script if any
      const existingScript = document.querySelector('script[src="https://plausible.io/js/script.file-downloads.hash.outbound-links.pageview-props.revenue.tagged-events.js"]');
      if (existingScript) {
        existingScript.remove();
      }

      // Dynamically add the Plausible script
      const script = document.createElement('script');
      script.src = 'https://plausible.io/js/script.file-downloads.hash.outbound-links.pageview-props.revenue.tagged-events.js';
      script.defer = true;
      script.setAttribute('data-domain', hostname); // Use the current hostname
      document.head.appendChild(script);

      // Initialize the Plausible function
      script.onload = () => {
        window.plausible = window.plausible || function () {
          (window.plausible.q = window.plausible.q || []).push(arguments);
        };

        // Track the current pageview
        window.plausible('pageview');
      };
    };

    // Load script initially
    loadPlausibleScript();

    // Re-load the script on every route change
    const router = useRouter();
    router.afterEach(() => {
      loadPlausibleScript();
    });
  }
});