import { ref } from 'vue';

// Global reactive state object for email sending
const status = ref({
  isLoading: false,
  success: false,
  error: null,
});

export function useEmail() {
  // Function to update the entire status object
  function updateStatus(newStatus) {
    status.value = { ...status.value, ...newStatus };
  }

  return {
    status,
    updateStatus,
  };
}
