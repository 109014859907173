<template>
    <button
      v-if="showScrollButton"
      @click="scrollToTop"
      class="scroll-to-top fixed bottom-4 right-4 p-2 bg-black text-white rounded-full shadow-lg w-10 h-10 z-[99999] flex flex-col items-center justify-center hover-slide-up"
      aria-label="Scroll to top"
    >
      <i class="fas fa-angle-up"></i>
      <span class="text-xs opacity-0 hover-text">Top</span>
    </button>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  
  const showScrollButton = ref(false);
  
  const handleScroll = () => {
    showScrollButton.value = window.scrollY > 100; // Show button if user scrolls more than 100px from the top
  };
  
  const scrollToTop = () => {
    const scrollDuration = 500; // Duration in milliseconds (1 second)
    const scrollStep = -window.scrollY / (scrollDuration / 15); // Calculate scroll step
  
    const scroll = () => {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep); // Scroll by step size
        requestAnimationFrame(scroll); // Continue scrolling
      }
    };
    requestAnimationFrame(scroll); // Start the scroll animation
  };
  
  // Attach the scroll event on component mount
  onMounted(() => {
    window.addEventListener('scroll', handleScroll);
  });
  
  // Detach the scroll event when the component is unmounted
  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
  });
  </script>
  
  <style scoped>
  .scroll-to-top {
    padding-top: 25px;
  }
  
  /* Add hover effect for the icon to slide up slightly */
  .hover-slide-up i {
    transition: transform 0.2s ease; /* Smooth transition for the sliding effect */
  }
  
  .hover-slide-up:hover i {
    transform: translateY(-8px); /* Slide the icon up by 4px when hovering */
  }
  
  /* Add styles for the text 'Top' */
  .hover-slide-up span {
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition for the text visibility */
  }
  
  .hover-slide-up:hover .hover-text {
    opacity: 1; /* Show text when hovering */
    transform: translateY(-10px); /* Move the text slightly upwards */
  }
  </style>
  