export default defineNuxtPlugin((nuxtApp) => {
  console.log('AI Plugin: Initializing...');

  const processImageWithPrompt = async (imageUrl, prompt) => {
    console.log('AI Plugin: Processing image with prompt', { imageUrl, prompt });
    const apiUrl = `http://localhost:5001/ai?key=DU1eYMDG7j8yb199YDPg3`;
    
    console.log('AI Plugin: Preparing request to:', apiUrl);
    const headers = {
      "Content-Type": "application/json",
    };

    const body = JSON.stringify({
      documentUrl: imageUrl,
      prompt,
    });
    console.log('AI Plugin: Request payload:', body);

    try {
      console.log('AI Plugin: Sending request...');
      const response = await fetch(apiUrl, {
        method: "POST",
        headers,
        body,
      });
      console.log('AI Plugin: Response status:', response.status);

      const data = await response.json();
      console.log('AI Plugin: Response data:', data);
      
      if (response.ok) {
        console.log('AI Plugin: Successfully processed image');
        return data.result || "Sorry, I couldn't process the image.";
      } else {
        console.error("AI Plugin: Error processing image:", data);
        return "Sorry, I couldn't process the image.";
      }
    } catch (error) {
      console.error("AI Plugin: Network error processing image:", error);
      return "Sorry, I couldn't process the image.";
    }
  };

  console.log('AI Plugin: Registering processImageWithPrompt function');
  nuxtApp.provide("processImageWithPrompt", processImageWithPrompt);
  console.log('AI Plugin: Initialization complete');
});