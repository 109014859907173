// padding.js

import { sizeMap } from './sizeMap.js';

// Convert Tailwind padding object to corresponding CSS values
export function padding(paddingObject) {
  if (typeof paddingObject !== 'object' || !paddingObject) {
    return {}; // Return an empty object if the input is not valid
  }

  const paddingStyles = {};
  
  // Handle each side individually
  if (paddingObject.left) {
    paddingStyles.paddingLeft = sizeMap[paddingObject.left] || '0px';
  }
  if (paddingObject.right) {
    paddingStyles.paddingRight = sizeMap[paddingObject.right] || '0px';
  }
  if (paddingObject.top) {
    paddingStyles.paddingTop = sizeMap[paddingObject.top] || '0px';
  }
  if (paddingObject.bottom) {
    paddingStyles.paddingBottom = sizeMap[paddingObject.bottom] || '0px';
  }

  return paddingStyles;
}
