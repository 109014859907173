// plugins/loadingOverlay.client.js
export default defineNuxtPlugin(() => {
  let overlayElement = null;

  const createOverlay = () => {
    if (overlayElement) return;

    overlayElement = document.createElement('div');
    overlayElement.id = 'loading-overlay';

    Object.assign(overlayElement.style, {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '24px',
      zIndex: '9999',
      flexDirection: 'column', // Added to support text and button layout
      transition: 'opacity 0.3s ease',
      opacity: 0,
    });

    document.body.appendChild(overlayElement);
  };

  const showOverlay = (message = 'Loading...') => {
    createOverlay();
    overlayElement.style.opacity = 1;
    overlayElement.innerHTML = `<span>${message}</span>`;
  };

  const hideOverlay = () => {
    if (overlayElement) {
      overlayElement.style.opacity = 0;
      setTimeout(() => removeOverlay(), 300);
    }
  };

  // Update showErrorOverlay to add a close button
  const showErrorOverlay = (message = 'An error occurred') => {
    createOverlay();
    overlayElement.style.backgroundColor = 'rgba(255, 0, 0, 0.7)';
    overlayElement.innerHTML = `
      <span>${message}</span>
      <button id="close-overlay-button" style="
        margin-top: 20px;
        background-color: white;
        color: red;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
      ">Close</button>
    `;

    // Add event listener to the button for closing the overlay
    const closeButton = document.getElementById('close-overlay-button');
    closeButton.addEventListener('click', hideOverlay);
  };

  const removeOverlay = () => {
    if (overlayElement) {
      document.body.removeChild(overlayElement);
      overlayElement = null;
    }
  };

  return {
    provide: {
      loadingOverlay: {
        show: showOverlay,
        hide: hideOverlay,
        showError: showErrorOverlay,
      },
    },
  };
});
