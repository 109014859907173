<template>
    <div class="bg-gray-800 text-white p-4 rounded-md shadow-lg">
      <p>{{ message }}</p>
    </div>
  </template>
  
  <script setup>
  const props = defineProps({
    message: { type: String, required: true },
    duration: { type: Number, default: 3000 },
  });
  
  const emit = defineEmits(['close']);
  
  // Automatically emit 'close' after the duration
  setTimeout(() => {
    emit('close');
  }, props.duration);
  </script>
  