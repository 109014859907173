<!-- components/Loader.vue -->
<template>
  <transition name="fade-bg" @after-leave="onFadeComplete">
    <div v-if="showBg" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <transition name="slide-up" @after-leave="hideBackground">
        <div v-if="showContent" class="relative">
          <div class="submit flex items-center">
            <div class="clock"></div>
            <div class="loading">{{ text }}{{ loadingDots }}</div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  text: {
    type: String,
    default: 'Loading', // Default loading text
  },
});

// Ref to control background and content separately
const showContent = ref(props.show);
const showBg = ref(props.show);
const loadingDots = ref(''); // Dots for the loading effect
let dotInterval;

// Watch for when the `show` prop changes
watch(() => props.show, (newVal) => {
  if (newVal) {
    showContent.value = true;
    showBg.value = true;
    startLoadingDots();
  } else {
    stopLoadingDots();
    showContent.value = false; // Slide up first
  }
});

// Function to hide background after slide-up completes
const hideBackground = () => {
  showBg.value = false; // Now fade out the background
};

// Optional callback after fade-out completes
const onFadeComplete = () => {
  //console.log('Animation completed');
};

// Start the loading dots animation
const startLoadingDots = () => {
  let dots = 0;
  dotInterval = setInterval(() => {
    dots = (dots + 1) % 4;
    loadingDots.value = '.'.repeat(dots);
  }, 500); // Change dots every 500ms
};

// Stop the loading dots animation
const stopLoadingDots = () => {
  clearInterval(dotInterval);
  loadingDots.value = ''; // Reset dots when hiding
};

onMounted(() => {
  if (props.show) {
    startLoadingDots();
  }
});
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai&display=swap');

/* Full screen container */
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Submit container */
.submit {
  padding: 8px 10px;
  min-width: 220px;
  font-size: 17px;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 1.2px;
  border-radius: 5px;
  background: #000000a8;
  display: flex;
  align-items: center; /* Center items vertically */
  font-family: 'Noto Sans Thai', sans-serif;
}

/* Loading text animation */
.loading {
  cursor: not-allowed;
  margin-left: 15px; /* Spacing */
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  padding-left: 10px;
  color: #c1c1c1;
}

/* Clock animation */
.clock {
  border-radius: 50%;
  border: 2px solid #c1c1c1;
  height: 20px;
  width: 20px;
  position: relative;
}

.clock:after, .clock:before {
  content: "";
  position: absolute;
  background-color: #c1c1c1;
  height: 8px;
  width: 2px;
  border-radius: 5px;
  transform-origin: 50% 80%;
}

.clock:after {
  top: 2px;
  left: 45%;
  animation: grdAiguille 2s linear infinite;
}

@keyframes grdAiguille {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.clock:before {
  top: 2px;
  left: 45%;
  animation: ptAiguille 12s linear infinite;
}

@keyframes ptAiguille {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* ------------------ Animations ------------------ */
/* Fade transition for the background */
.fade-bg-enter-active, .fade-bg-leave-active {
  transition: opacity 0.5s ease;
}
.fade-bg-enter, .fade-bg-leave-to {
  opacity: 0;
}

/* Slide-up transition for submit container */
.slide-up-enter-active, .slide-up-leave-active {
  transition: all 0.5s ease;
}
.slide-up-enter, .slide-up-leave-to {
  transform: translateY(0);
}
.slide-up-leave, .slide-up-enter-to {
  transform: translateY(-50px);
  opacity: 0;
}
</style>
