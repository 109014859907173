// composables/useHistory.js
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const lastRoute = ref(null);  // To store the last route
const currentRoute = ref(null); // To store the current route

export function useHistory() {
  const router = useRouter();

  const recordHistory = () => {
    // Use router afterEach to capture route changes
    router.afterEach((to, from) => {
      lastRoute.value = from.fullPath;  // Store the previous route
      currentRoute.value = to.fullPath; // Store the current route
    });
  };

  return {
    lastRoute,
    currentRoute,
    recordHistory
  };
}
