// composables/useRender.js
import { ref } from 'vue';

const status = ref(false); // Global reactive status

export function useRender() {
  // Function to update the status
  function updateStatus(newStatus) {
    status.value = newStatus;
  }

  return {
    status,
    updateStatus,
  };
}
