export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('button', {
      mounted(el, binding) {
        const {
          iconClass = 'fas fa-check',
          normalColor = 'bg-blue-600', // Default background color
          hoverColor = 'bg-blue-700', // Default background color
          normalText = 'Submit',
          processText = 'Processing...',
          successText = 'Success',
          errorText = 'Error',
          layout = {}
        } = binding.value || {};
  
        // Set up base layout and allow overrides
        applyButtonLayout(el, layout, normalColor, hoverColor); // Pass normalColor explicitly for normal state
  
        // Create icon and divider elements and append them to the button
        const divider = document.createElement('span');
        divider.style.borderLeft = '1px solid rgb(255 255 255 / 30%)';
        divider.style.height = '24px'; // Adjust height if needed
        divider.style.margin = '0 11px'; // Spacing for divider
  
        const icon = document.createElement('i');
        updateIcon(icon, iconClass); // Set initial icon
  
        el.appendChild(divider); // Append divider between text and icon
        el.appendChild(icon); // Append icon after divider
  
        // Set initial text
        el.innerText = normalText;
        el.appendChild(divider); // Reappend divider after text
        el.appendChild(icon); // Reappend icon after text
  
        // Set up dynamic button states
        el.apiState = { loading: false, success: false, error: false };
  
        // Utility function to change the button's state, text, and background color
        const setState = (state) => {
          const states = {
            loading: {
              icon: 'fas fa-spinner fa-spin',
              text: processText,
              color: 'bg-blue-600', // Loading color
              disabled: true,
              cursor: 'not-allowed'
            },
            success: {
              icon: 'fas fa-check-circle',
              text: successText,
              color: 'bg-green-600', // Success color
              resetAfter: 2000, // Automatically reset after 2 seconds
              disabled: true,
              cursor: 'not-allowed'
            },
            error: {
              icon: 'fas fa-exclamation-triangle',
              text: errorText,
              color: 'bg-red-600', // Error color
              resetAfter: 2000, // Automatically reset after 2 seconds
              disabled: true,
              cursor: 'not-allowed'
            },
            normal: {
              icon: iconClass,
              text: normalText,
              color: normalColor, // Use normalColor passed in from the directive's value for normal state
              disabled: false,
              cursor: 'pointer'
            }
          };
  
          const currentState = states[state];
  
          // Update the icon, text, and background color
          updateIcon(icon, currentState.icon);
          el.innerText = currentState.text;
          el.appendChild(divider); // Ensure divider is reappended after text change
          el.appendChild(icon); // Ensure icon is reappended after text change
  
          // Clear previous background color classes
          el.classList.remove('bg-blue-600', 'bg-green-600', 'bg-red-600', normalColor);
  
          // Apply the current state's background color
          el.classList.add(currentState.color);
  
          // Disable the button in certain states
          el.style.pointerEvents = currentState.disabled ? 'none' : 'auto';
          el.style.opacity = currentState.disabled ? '0.5' : '1';
  
          // Update the cursor style
          el.style.cursor = currentState.cursor;
  
          // Reset to the normal state after success or error if specified
          if (currentState.resetAfter) {
            setTimeout(() => setState('normal'), currentState.resetAfter);
          }
        };
  
        el.setState = setState; // Attach setState method for external control
      }
    });
  
    // Function to apply base button layout and allow custom overrides
    function applyButtonLayout(el, customLayout, normalColor, hoverColor) {
      const defaultLayout = {
        base: [
          'inline-flex',
          'items-center',
          'justify-center',
          'px-4',
          'py-2',
          'border',
          'border-transparent',
          'rounded-sm',
          'shadow-md',
          'text-white',
          normalColor, // Use normalColor for normal state
          `hover:${hoverColor}`,
          'hover:shadow-lg',
          'focus:outline-none',
          'focus:ring-2',
          'focus:ring-offset-2',
          'focus:ring-blue-500',
          'transition',
          'duration-150',
          'ease-in-out'
        ]
      };
  
      // Apply base layout and merge with custom overrides
      const finalLayout = {
        ...defaultLayout,
        ...customLayout
      };
  
      // Add new layout classes while keeping existing custom classes
      el.classList.add(...finalLayout.base);
    }
  
    // Function to update icon dynamically
    function updateIcon(icon, iconClass) {
      icon.className = ''; // Clear current icon classes
      icon.classList.add(...iconClass.split(' ')); // Add new icon classes
      icon.style.marginLeft = '0px'; // Add spacing for the icon
    }
  });
  