import { jwtVerify } from 'jose'; // Import the browser-compatible verification method
import { useCookie } from '#app'; // Import cookie utilities from Nuxt

export function useAuth() {
  const secretKey = new TextEncoder().encode('ZCOKU1v3TO2flcOqCdrJ3vWbWhmnZNQn'); // Replace with your actual secret key
  const authToken = useCookie('_account');

  // Function to decode and verify JWT
  const decodeToken = async () => {
    if (!authToken.value) {
      //return null; // No token present
      return { user: 'public', role: 'guest' };
    }

    try {
      const { payload } = await jwtVerify(authToken.value, secretKey, {
        algorithms: ['HS256'], // Ensure only HS256 is allowed
      });
      return payload; // Return decoded token data
    } catch (err) {
      console.error('Token verification failed', err);
      return null; // Invalid or expired token
    }
  };

  // Function to check if the user is authenticated
  const isAuthenticated = async () => {
    const tokenData = await decodeToken();
    return !!tokenData; // If token data exists, the user is authenticated
  };

  return {
    decodeToken,
    isAuthenticated,
  };
}
