import { padding, margin, background, width, position, border } from '~/components/builder/plugin/tailwind2css.js';

export default defineNuxtPlugin((nuxtApp) => {
  const screenWidth = ref(process.client ? window.innerWidth : 0);

  if (process.client) {
    // Listen for resize events to update screen width dynamically
    window.addEventListener('resize', () => {
      screenWidth.value = window.innerWidth;
    });
  }

  nuxtApp.provide('getRowOutStyles', (row) => {
    const styles = {
      position: position(row.position),
      ...margin(row.margin),
      ...padding(row.padding?.out),
      ...background(row.background, 'out'),
      borderWidth: border.width(row.border),
      borderStyle: border.style(row.border),
      borderColor: border.color(row.border),
      borderRadius: border.radius(row.border),
    };
    return styles;
  });

  nuxtApp.provide('getRowInStyles', (row) => {
    const generateGapScale = (baseSize = 4, maxScale = 96, ratio = 1) => {
      const scale = {};
      for (let i = 0; i <= maxScale; i++) {
        scale[i] = `${i * baseSize * ratio}px`;
      }
      return scale;
    };

    const tailwindGapScale = generateGapScale(4, 96, 1);

    // Determine responsive columns based on dynamic screen width
    let responsiveCols = 1;
    if (screenWidth.value >= 1024) {
      // Desktop
      responsiveCols = row?.column?.desktop || 1;
    } else if (screenWidth.value >= 640) {
      // Tablet
      responsiveCols = row?.column?.tablet || 1;
    } else {
      // Mobile
      responsiveCols = row?.column?.mobile || 1;
    }

    const gapValue = row.gap !== undefined
      ? (tailwindGapScale[row.gap] || (!isNaN(row.gap) ? `${row.gap}px` : row.gap))
      : '0px';

    const calculateHalfGap = (gap) => {
      if (!gap) return '0px';
      const match = gap.match(/^([\d.]+)([a-z%]*)$/i);
      if (!match) return '0px';
      const value = parseFloat(match[1]);
      const unit = match[2] || 'px';
      return `${value / 2}${unit}`;
    };

    const paddingLeftRight = calculateHalfGap(gapValue);

    const styles = {
      display: 'grid',
      gridTemplateColumns: `repeat(${responsiveCols}, minmax(0, 1fr))`,
      gap: gapValue,
      paddingLeft: paddingLeftRight,
      paddingRight: paddingLeftRight,
      maxWidth: width(row.width),
      marginLeft: 'auto',
      marginRight: 'auto',
      ...padding(row.padding?.in),
      ...background(row.background, 'in'),
    };

    return styles;
  });

  nuxtApp.provide('getColumnStyles', (column) => {
    const styles = {
      gridColumn: `span ${column.colspan}`,
      position: position(column.position),
      ...margin(column.margin),
      ...padding(column.padding?.out),
      ...background(column.background, 'out'),
      borderWidth: border.width(column.border),
      borderStyle: border.style(column.border),
      borderColor: border.color(column.border),
      borderRadius: border.radius(column.border),
    };
    return styles;
  });

  nuxtApp.provide('getVisibilityClasses', (element) => {
    const classes = [];

    if (element.visible?.mobile !== false) {
      classes.push('visible-mobile');
    } else {
      classes.push('hidden-mobile');
    }

    if (element.visible?.tablet !== false) {
      classes.push('visible-tablet');
    } else {
      classes.push('hidden-tablet');
    }

    if (element.visible?.laptop !== false) {
      classes.push('visible-laptop');
    } else {
      classes.push('hidden-laptop');
    }

    if (element.visible?.desktop !== false) {
      classes.push('visible-desktop');
    } else {
      classes.push('hidden-desktop');
    }

    return classes;
  });
});
