import { default as _91post_93WCMafbL6bWMeta } from "/opt/buildhome/repo/pages/[page]/[post].vue?macro=true";
import { default as indexvndeQliY0eMeta } from "/opt/buildhome/repo/pages/[page]/index.vue?macro=true";
import { default as aiaZoMcF36LOMeta } from "/opt/buildhome/repo/pages/ai.vue?macro=true";
import { default as BillPaymentPEtnuAYpmGMeta } from "/opt/buildhome/repo/pages/billing/_plugin/BillPayment.vue?macro=true";
import { default as BillStatic1FpcO933zFMeta } from "/opt/buildhome/repo/pages/billing/_plugin/BillStatic.vue?macro=true";
import { default as OrderNLPkMeCUBuMeta } from "/opt/buildhome/repo/pages/billing/_plugin/Order.vue?macro=true";
import { default as _91order_93lgTSrdr4irMeta } from "/opt/buildhome/repo/pages/billing/order/[order].vue?macro=true";
import { default as cartmlZ8y0ESmPMeta } from "/opt/buildhome/repo/pages/billing/order/cart.vue?macro=true";
import { default as checkoutIDaLEr8sLjMeta } from "/opt/buildhome/repo/pages/billing/order/checkout.vue?macro=true";
import { default as payiAGAQMY9h4Meta } from "/opt/buildhome/repo/pages/billing/pay.vue?macro=true";
import { default as _slugWs41MlLQobMeta } from "/opt/buildhome/repo/pages/billing/payment/_slug.vue?macro=true";
import { default as _91slug_9377mCZIeR2CMeta } from "/opt/buildhome/repo/pages/billing/payment/[slug].vue?macro=true";
import { default as indexVbl1ygM7P4Meta } from "/opt/buildhome/repo/pages/billing/payment/index.vue?macro=true";
import { default as PromptPay20H2fzYFXYMeta } from "/opt/buildhome/repo/pages/billing/resource/PromptPay.vue?macro=true";
import { default as StepD7bPCgmNsbMeta } from "/opt/buildhome/repo/pages/billing/resource/Step.vue?macro=true";
import { default as UploadSlipwD94rDaIDdMeta } from "/opt/buildhome/repo/pages/billing/resource/UploadSlip.vue?macro=true";
import { default as _91order_93IPYXekNeMbMeta } from "/opt/buildhome/repo/pages/billing/subscribe/[order].vue?macro=true";
import { default as thankyouDcwB4TAk3nMeta } from "/opt/buildhome/repo/pages/billing/thankyou.vue?macro=true";
import { default as _91calendar_93RwX1e7KolGMeta } from "/opt/buildhome/repo/pages/calendar/[calendar].vue?macro=true";
import { default as CalendarTEAsmXNA0wMeta } from "/opt/buildhome/repo/pages/calendar/Calendar.vue?macro=true";
import { default as emailvkAdjNqXLJMeta } from "/opt/buildhome/repo/pages/calendar/email.vue?macro=true";
import { default as indexc6q13wlq0SMeta } from "/opt/buildhome/repo/pages/calendar/index.vue?macro=true";
import { default as findNnMi8wD8szMeta } from "/opt/buildhome/repo/pages/certification/find.vue?macro=true";
import { default as dashboardDWKuzd36f1Meta } from "/opt/buildhome/repo/pages/cluster/dashboard.vue?macro=true";
import { default as filemanager7GmqgP3ZysMeta } from "/opt/buildhome/repo/pages/cluster/filemanager.vue?macro=true";
import { default as indexB2mncPmyn4Meta } from "/opt/buildhome/repo/pages/cluster/index.vue?macro=true";
import { default as liffMjZTHzIIv3Meta } from "/opt/buildhome/repo/pages/cluster/liff.vue?macro=true";
import { default as index1SSCub84Y9Meta } from "/opt/buildhome/repo/pages/cluster/operating_model/index.vue?macro=true";
import { default as newyNOEpwKw4HMeta } from "/opt/buildhome/repo/pages/cluster/operating_model/new.vue?macro=true";
import { default as orderQ0RLJ9LwbpMeta } from "/opt/buildhome/repo/pages/cluster/order.vue?macro=true";
import { default as panelNJeoZkOy75Meta } from "/opt/buildhome/repo/pages/cluster/panel.vue?macro=true";
import { default as BillingSection4f0j5mn3BvMeta } from "/opt/buildhome/repo/pages/cluster/pos/BillingSection.vue?macro=true";
import { default as Calculatorw28Nmx1TQOMeta } from "/opt/buildhome/repo/pages/cluster/pos/Calculator.vue?macro=true";
import { default as CheckoutSectionsCMdrHUxItMeta } from "/opt/buildhome/repo/pages/cluster/pos/CheckoutSection.vue?macro=true";
import { default as Order903LrtdBvcMeta } from "/opt/buildhome/repo/pages/cluster/pos/Order.vue?macro=true";
import { default as ProductSectionDcaV4l8XziMeta } from "/opt/buildhome/repo/pages/cluster/pos/ProductSection.vue?macro=true";
import { default as StockManagerdzBQVC7C8IMeta } from "/opt/buildhome/repo/pages/cluster/pos/StockManager.vue?macro=true";
import { default as posmVQbtWO1tPMeta } from "/opt/buildhome/repo/pages/cluster/pos.vue?macro=true";
import { default as indexSVMLewab0GMeta } from "/opt/buildhome/repo/pages/cluster/products/index.vue?macro=true";
import { default as scan0w0M6myarrMeta } from "/opt/buildhome/repo/pages/cluster/scan.vue?macro=true";
import { default as vendortywMQrfurSMeta } from "/opt/buildhome/repo/pages/cluster/vendor.vue?macro=true";
import { default as _91post_93h9UzBxRN9EMeta } from "/opt/buildhome/repo/pages/cms-blog/[category]/[post].vue?macro=true";
import { default as _91slug_93CIKiTWBQCbMeta } from "/opt/buildhome/repo/pages/cms-blog/[slug].vue?macro=true";
import { default as indexnCEqGjXZ61Meta } from "/opt/buildhome/repo/pages/cms-blog/index.vue?macro=true";
import { default as indexRONAk9yzQ7Meta } from "/opt/buildhome/repo/pages/cms/_component/page/admin/index.vue?macro=true";
import { default as indexGBCJD1rMpgMeta } from "/opt/buildhome/repo/pages/cms/_component/page/guest/index.vue?macro=true";
import { default as indexxglvAsiivOMeta } from "/opt/buildhome/repo/pages/cms/_component/page/manager/index.vue?macro=true";
import { default as indexgFH1B0XbcRMeta } from "/opt/buildhome/repo/pages/cms/_component/page/user/index.vue?macro=true";
import { default as _91slug_93DQS8DQrisiMeta } from "/opt/buildhome/repo/pages/cms/page/[slug].vue?macro=true";
import { default as config8rCSLLqpltMeta } from "/opt/buildhome/repo/pages/config.vue?macro=true";
import { default as customhfyaHdprHdMeta } from "/opt/buildhome/repo/pages/custom.vue?macro=true";
import { default as elementaUZGUxqr7EMeta } from "/opt/buildhome/repo/pages/demo/element.vue?macro=true";
import { default as doa_45register_4568CWYyR1s5ucMeta } from "/opt/buildhome/repo/pages/doa-register-68.vue?macro=true";
import { default as manage_eventCOQwUReBQ7Meta } from "/opt/buildhome/repo/pages/event/_plugin/manage_event.vue?macro=true";
import { default as manage_giftESsU2MMVSUMeta } from "/opt/buildhome/repo/pages/event/_plugin/manage_gift.vue?macro=true";
import { default as manage_influxghUHqrcBOMeta } from "/opt/buildhome/repo/pages/event/_plugin/manage_influ.vue?macro=true";
import { default as managerTDdkD2x8dpMeta } from "/opt/buildhome/repo/pages/event/_plugin/manager.vue?macro=true";
import { default as eventiSfHo5QT0bMeta } from "/opt/buildhome/repo/pages/event/_plugin/module/event.vue?macro=true";
import { default as gift_listzYl6sgokcJMeta } from "/opt/buildhome/repo/pages/event/_plugin/module/gift_list.vue?macro=true";
import { default as giftq3aXewpHRmMeta } from "/opt/buildhome/repo/pages/event/_plugin/module/gift.vue?macro=true";
import { default as influencer9Zm9E8Zx72Meta } from "/opt/buildhome/repo/pages/event/_plugin/module/influencer.vue?macro=true";
import { default as packageMaqJMfF67JMeta } from "/opt/buildhome/repo/pages/event/_plugin/module/package.vue?macro=true";
import { default as _91slug_93NvCz4eGHWOMeta } from "/opt/buildhome/repo/pages/event/[slug].vue?macro=true";
import { default as indexnjZm1Usu1TMeta } from "/opt/buildhome/repo/pages/event/index.vue?macro=true";
import { default as _91code_93EpwaDuunx9Meta } from "/opt/buildhome/repo/pages/external/storage/file/[code].vue?macro=true";
import { default as _91code_93wJHSQGlyFYMeta } from "/opt/buildhome/repo/pages/external/storage/folder/[code].vue?macro=true";
import { default as baseDataJt1FHMPjnZMeta } from "/opt/buildhome/repo/pages/hora/baseData.js?macro=true";
import { default as calculationMethodscN7XttflWJMeta } from "/opt/buildhome/repo/pages/hora/calculationMethods.js?macro=true";
import { default as indexc4zgumGKUTMeta } from "/opt/buildhome/repo/pages/hora/index.vue?macro=true";
import { default as rakkana6CWN4aMRptMeta } from "/opt/buildhome/repo/pages/hora/rakkana.vue?macro=true";
import { default as staticAnEJjeXC4oMeta } from "/opt/buildhome/repo/pages/hora/static.vue?macro=true";
import { default as indexdz1Og3J4gXMeta } from "/opt/buildhome/repo/pages/idea/index.vue?macro=true";
import { default as LinkableObjects88n94jNAxNMeta } from "/opt/buildhome/repo/pages/idea/LinkableObjects.vue?macro=true";
import { default as LinkableObjectsWithStaticVpm3TnCUk3Meta } from "/opt/buildhome/repo/pages/idea/LinkableObjectsWithStatic.vue?macro=true";
import { default as StaticGridWithLinkagegcBmSNx87rMeta } from "/opt/buildhome/repo/pages/idea/StaticGridWithLinkage.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as _91slug_93hP0uZXvdedMeta } from "/opt/buildhome/repo/pages/influencer/[slug].vue?macro=true";
import { default as gift21nw2RmeVQMeta } from "/opt/buildhome/repo/pages/influencer/gift.vue?macro=true";
import { default as indexuzH4q7IrNwMeta } from "/opt/buildhome/repo/pages/lesson/_component/assessment/guest/index.vue?macro=true";
import { default as indexLdX2FNzycAMeta } from "/opt/buildhome/repo/pages/lesson/_component/calendar/guest/index.vue?macro=true";
import { default as indexuaVOA1tjiSMeta } from "/opt/buildhome/repo/pages/lesson/_component/calendar/user/index.vue?macro=true";
import { default as indexwaLR7sg5DXMeta } from "/opt/buildhome/repo/pages/lesson/_component/detail/admin/index.vue?macro=true";
import { default as indexcr5UjCktOnMeta } from "/opt/buildhome/repo/pages/lesson/_component/detail/guest/index.vue?macro=true";
import { default as indexuQ1dNrPJg2Meta } from "/opt/buildhome/repo/pages/lesson/_component/detail/manager/index.vue?macro=true";
import { default as indexZeiWhp61rGMeta } from "/opt/buildhome/repo/pages/lesson/_component/detail/user/index.vue?macro=true";
import { default as indexWBA6gbFbMrMeta } from "/opt/buildhome/repo/pages/lesson/_component/enroll/guest/index.vue?macro=true";
import { default as indexq7O66d3TJmMeta } from "/opt/buildhome/repo/pages/lesson/_component/index/admin/index.vue?macro=true";
import { default as indexxw9a9Hv8yoMeta } from "/opt/buildhome/repo/pages/lesson/_component/index/guest/index.vue?macro=true";
import { default as indexTrUb304gwyMeta } from "/opt/buildhome/repo/pages/lesson/_component/index/manager/index.vue?macro=true";
import { default as indexJsviKw5qdoMeta } from "/opt/buildhome/repo/pages/lesson/_component/index/user/index.vue?macro=true";
import { default as index0NLqHWPymvMeta } from "/opt/buildhome/repo/pages/lesson/_component/play/guest/index.vue?macro=true";
import { default as AskContentBwO7T4MjD1Meta } from "/opt/buildhome/repo/pages/lesson/_plugin/AskContent.vue?macro=true";
import { default as Examu6UcTjp13VMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/assessment/Exam.vue?macro=true";
import { default as Intron2B2MI7aMXMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/assessment/Intro.vue?macro=true";
import { default as Result2H1TVIU6lSMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/assessment/Result.vue?macro=true";
import { default as webcamRjDA07ESSmMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/assessment/webcam.vue?macro=true";
import { default as authennPcu6eTpuoMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/builder/module/authen.vue?macro=true";
import { default as enrolloxORyWKzT5Meta } from "/opt/buildhome/repo/pages/lesson/_plugin/builder/module/enroll.vue?macro=true";
import { default as BypassPlayer0L6L55eH4jMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/BypassPlayer.vue?macro=true";
import { default as ModalBCCpumNFE5Meta } from "/opt/buildhome/repo/pages/lesson/_plugin/calendar/Modal.vue?macro=true";
import { default as RenderQQ6TE67WDiMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/certification/Render.vue?macro=true";
import { default as Curriculum3dw2IwyPN0Meta } from "/opt/buildhome/repo/pages/lesson/_plugin/Curriculum.vue?macro=true";
import { default as DescriptioneUWxaL1hczMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/Description.vue?macro=true";
import { default as EnrollButtonSucbHC08vuMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/EnrollButton.vue?macro=true";
import { default as ExamPlayerx0RvFc7ll8Meta } from "/opt/buildhome/repo/pages/lesson/_plugin/ExamPlayer.vue?macro=true";
import { default as IdleCheckerF7MKqCai7BMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/IdleChecker.vue?macro=true";
import { default as PlayerLKZZ2SnOX0Meta } from "/opt/buildhome/repo/pages/lesson/_plugin/Player.vue?macro=true";
import { default as PlayerDemockTv3GhZQnMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/PlayerDemo.vue?macro=true";
import { default as PlayerItem1qdMI5m3BOMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/PlayerItem.vue?macro=true";
import { default as Pricem9XC8O559ZMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/Price.vue?macro=true";
import { default as ProxyPlayerLh1OobCvYMMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/ProxyPlayer.vue?macro=true";
import { default as RatingModalWeOPyg32u7Meta } from "/opt/buildhome/repo/pages/lesson/_plugin/RatingModal.vue?macro=true";
import { default as scheduleTableyDoKnPjg0OMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/scheduleTable.vue?macro=true";
import { default as liveD7FJrydBhpMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/stun/live.vue?macro=true";
import { default as rtcK2Ev2JW65bMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/stun/rtc.vue?macro=true";
import { default as viewyDLThHOzveMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/stun/view.vue?macro=true";
import { default as Survey4rqGk5wSR4Meta } from "/opt/buildhome/repo/pages/lesson/_plugin/Survey.vue?macro=true";
import { default as webcamvHRgkIJ9KrMeta } from "/opt/buildhome/repo/pages/lesson/_plugin/webcam.vue?macro=true";
import { default as _91exam_93NF1prEvKisMeta } from "/opt/buildhome/repo/pages/lesson/assessment/[lesson]/[exam].vue?macro=true";
import { default as _91year_93j0OSYokWVnMeta } from "/opt/buildhome/repo/pages/lesson/calendar/[year].vue?macro=true";
import { default as _91cert_93lHkj2nHQOYMeta } from "/opt/buildhome/repo/pages/lesson/certification/[slug]/[cert].vue?macro=true";
import { default as indexWk1l4qxbSuMeta } from "/opt/buildhome/repo/pages/lesson/certification/[slug]/index.vue?macro=true";
import { default as _91slug_93fsEeLop8MLMeta } from "/opt/buildhome/repo/pages/lesson/certification/public/[slug].vue?macro=true";
import { default as searchomeDAcCu9LMeta } from "/opt/buildhome/repo/pages/lesson/certification/search.vue?macro=true";
import { default as _91slug_93FzMBnTxLgKMeta } from "/opt/buildhome/repo/pages/lesson/detail/[slug].vue?macro=true";
import { default as _91slug_93xTK1rLQOD7Meta } from "/opt/buildhome/repo/pages/lesson/enroll/[slug].vue?macro=true";
import { default as indexyP3rZjutoMMeta } from "/opt/buildhome/repo/pages/lesson/index.vue?macro=true";
import { default as _91slug_93O30CRFR5qHMeta } from "/opt/buildhome/repo/pages/lesson/play/[lesson]/[slug].vue?macro=true";
import { default as live6teoitWZ0pMeta } from "/opt/buildhome/repo/pages/lesson/stun/live.vue?macro=true";
import { default as view2hO241NpPwMeta } from "/opt/buildhome/repo/pages/lesson/stun/view.vue?macro=true";
import { default as indexO9f0mRTjJvMeta } from "/opt/buildhome/repo/pages/project/[group_id]/[project_id]/index.vue?macro=true";
import { default as indexhjuFOp2e1FMeta } from "/opt/buildhome/repo/pages/project/[group_id]/index.vue?macro=true";
import { default as agendaALSFbIJlURMeta } from "/opt/buildhome/repo/pages/project/admin/agenda.vue?macro=true";
import { default as calendarjwN1KQUZSGMeta } from "/opt/buildhome/repo/pages/project/admin/calendar.vue?macro=true";
import { default as CommentItemW7CNo4jZ6uMeta } from "/opt/buildhome/repo/pages/project/admin/CommentItem.vue?macro=true";
import { default as dashboardLISrxJx420Meta } from "/opt/buildhome/repo/pages/project/admin/dashboard.vue?macro=true";
import { default as detailMO16h7myQyMeta } from "/opt/buildhome/repo/pages/project/admin/detail.vue?macro=true";
import { default as ganttrbqjKsocCtMeta } from "/opt/buildhome/repo/pages/project/admin/gantt.vue?macro=true";
import { default as indexeKfaPZrG5dMeta } from "/opt/buildhome/repo/pages/project/admin/index.vue?macro=true";
import { default as ModalCategoryQs8f4zPzeFMeta } from "/opt/buildhome/repo/pages/project/admin/ModalCategory.vue?macro=true";
import { default as ModalStatushuLKnR9fbBMeta } from "/opt/buildhome/repo/pages/project/admin/ModalStatus.vue?macro=true";
import { default as ModalTaskbtji0yRVsqMeta } from "/opt/buildhome/repo/pages/project/admin/ModalTask.vue?macro=true";
import { default as ProjectFolderB5OhaRxIoeMeta } from "/opt/buildhome/repo/pages/project/admin/ProjectFolder.vue?macro=true";
import { default as table0wssd1mrkqMeta } from "/opt/buildhome/repo/pages/project/admin/table.vue?macro=true";
import { default as taskja2jDci0goMeta } from "/opt/buildhome/repo/pages/project/admin/task.vue?macro=true";
import { default as todoRwgIJFCIA5Meta } from "/opt/buildhome/repo/pages/project/admin/todo.vue?macro=true";
import { default as indexeHKoD1mwAsMeta } from "/opt/buildhome/repo/pages/project/index.vue?macro=true";
import { default as scan4vJQUcshElMeta } from "/opt/buildhome/repo/pages/scan.vue?macro=true";
import { default as indexhG3mCxoB6JMeta } from "/opt/buildhome/repo/pages/shop/_component/cart/guest/index.vue?macro=true";
import { default as indexH9Xstk42IMMeta } from "/opt/buildhome/repo/pages/shop/_component/cart/user/index.vue?macro=true";
import { default as indexh5xpB1g5IQMeta } from "/opt/buildhome/repo/pages/shop/_component/categorie/guest/index.vue?macro=true";
import { default as indexSijQAzvuDvMeta } from "/opt/buildhome/repo/pages/shop/_component/categorie/user/index.vue?macro=true";
import { default as index0gs34UmLDwMeta } from "/opt/buildhome/repo/pages/shop/_component/categories/guest/index.vue?macro=true";
import { default as indexEh6yx5xU8iMeta } from "/opt/buildhome/repo/pages/shop/_component/categories/user/index.vue?macro=true";
import { default as indexUgssHHwbtGMeta } from "/opt/buildhome/repo/pages/shop/_component/checkout/guest/index.vue?macro=true";
import { default as indexUvTioEMfuXMeta } from "/opt/buildhome/repo/pages/shop/_component/checkout/user/index.vue?macro=true";
import { default as indexSIwZz36PzyMeta } from "/opt/buildhome/repo/pages/shop/_component/product/guest/index.vue?macro=true";
import { default as indexUlBXaxsJHfMeta } from "/opt/buildhome/repo/pages/shop/_component/product/user/index.vue?macro=true";
import { default as indexxenr8J9lrVMeta } from "/opt/buildhome/repo/pages/shop/_component/products/guest/index.vue?macro=true";
import { default as indexhXuJ0oadxpMeta } from "/opt/buildhome/repo/pages/shop/_component/products/user/index.vue?macro=true";
import { default as indexxaB86ylQ0sMeta } from "/opt/buildhome/repo/pages/shop/_component/thankyou/guest/index.vue?macro=true";
import { default as indexb5LXthRj7rMeta } from "/opt/buildhome/repo/pages/shop/_component/thankyou/user/index.vue?macro=true";
import { default as galleryKIEzYCcWrYMeta } from "/opt/buildhome/repo/pages/shop/_plugin/product/gallery.vue?macro=true";
import { default as ratingCzgr7agWhcMeta } from "/opt/buildhome/repo/pages/shop/_plugin/product/rating.vue?macro=true";
import { default as swatchWM7Gz9jyifMeta } from "/opt/buildhome/repo/pages/shop/_plugin/product/swatch.vue?macro=true";
import { default as cartbhnrN3nivPMeta } from "/opt/buildhome/repo/pages/shop/cart.vue?macro=true";
import { default as _91code_93htWfGUDhBjMeta } from "/opt/buildhome/repo/pages/shop/categorie/[code].vue?macro=true";
import { default as indexKV1T6tKuqOMeta } from "/opt/buildhome/repo/pages/shop/categories/index.vue?macro=true";
import { default as checkoutRq8raSVa4QMeta } from "/opt/buildhome/repo/pages/shop/checkout.vue?macro=true";
import { default as _91sku_93weG8Qqvle5Meta } from "/opt/buildhome/repo/pages/shop/product/[sku].vue?macro=true";
import { default as indexcrpEmomSKsMeta } from "/opt/buildhome/repo/pages/shop/products/index.vue?macro=true";
import { default as _91order_93N3oNC1lXx4Meta } from "/opt/buildhome/repo/pages/shop/thankyou/[order].vue?macro=true";
import { default as Loginxcdfp7F2E3Meta } from "/opt/buildhome/repo/pages/user/_plugin/Login.vue?macro=true";
import { default as activateJOsMCzK429Meta } from "/opt/buildhome/repo/pages/user/activate.vue?macro=true";
import { default as callbackrchi2yp4pfMeta } from "/opt/buildhome/repo/pages/user/authen/line/callback.vue?macro=true";
import { default as indexrwxPrs5phTMeta } from "/opt/buildhome/repo/pages/user/authen/line/index.vue?macro=true";
import { default as browserIbYDBB8oo2Meta } from "/opt/buildhome/repo/pages/user/browser.vue?macro=true";
import { default as _91slug_93kySh2QzgpjMeta } from "/opt/buildhome/repo/pages/user/bypass/[slug].vue?macro=true";
import { default as dashboardnUSQWXhYgfMeta } from "/opt/buildhome/repo/pages/user/dashboard.vue?macro=true";
import { default as device_debugM78CYzV0mGMeta } from "/opt/buildhome/repo/pages/user/device_debug.vue?macro=true";
import { default as filemanager_2aN6sbY37OuMeta } from "/opt/buildhome/repo/pages/user/filemanager_2.vue?macro=true";
import { default as filemanagerVFXMhlUuFwMeta } from "/opt/buildhome/repo/pages/user/filemanager.vue?macro=true";
import { default as indexryEgDOXPYzMeta } from "/opt/buildhome/repo/pages/user/index.vue?macro=true";
import { default as loginyoMJEwcHgpMeta } from "/opt/buildhome/repo/pages/user/login.vue?macro=true";
import { default as message26dzJffmXAMeta } from "/opt/buildhome/repo/pages/user/message.vue?macro=true";
import { default as BillingSection5AGjNncnFtMeta } from "/opt/buildhome/repo/pages/user/pos/BillingSection.vue?macro=true";
import { default as CalculatorV1rbOneINWMeta } from "/opt/buildhome/repo/pages/user/pos/Calculator.vue?macro=true";
import { default as CheckoutSectionyQwXCx4tdEMeta } from "/opt/buildhome/repo/pages/user/pos/CheckoutSection.vue?macro=true";
import { default as OrderKpSpZDYINIMeta } from "/opt/buildhome/repo/pages/user/pos/Order.vue?macro=true";
import { default as ProductSectiongd7O9NvgHaMeta } from "/opt/buildhome/repo/pages/user/pos/ProductSection.vue?macro=true";
import { default as posDypze5mMMoMeta } from "/opt/buildhome/repo/pages/user/pos.vue?macro=true";
import { default as profileBvCiMYaQFoMeta } from "/opt/buildhome/repo/pages/user/profile.vue?macro=true";
import { default as qrcodeNZgdfcemnfMeta } from "/opt/buildhome/repo/pages/user/qrcode.vue?macro=true";
import { default as recovery5eRTm4S2PWMeta } from "/opt/buildhome/repo/pages/user/recovery.vue?macro=true";
import { default as registerhQMrspQYEvMeta } from "/opt/buildhome/repo/pages/user/register.vue?macro=true";
import { default as uploadpy4Y5ZSlZCMeta } from "/opt/buildhome/repo/pages/user/upload.vue?macro=true";
import { default as walletlD5fXazAtHMeta } from "/opt/buildhome/repo/pages/user/wallet.vue?macro=true";
export default [
  {
    name: "page-post",
    path: "/:page()/:post()",
    meta: _91post_93WCMafbL6bWMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[page]/[post].vue").then(m => m.default || m)
  },
  {
    name: "page",
    path: "/:page()",
    meta: indexvndeQliY0eMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[page]/index.vue").then(m => m.default || m)
  },
  {
    name: "ai",
    path: "/ai",
    component: () => import("/opt/buildhome/repo/pages/ai.vue").then(m => m.default || m)
  },
  {
    name: "billing-_plugin-BillPayment",
    path: "/billing/_plugin/BillPayment",
    component: () => import("/opt/buildhome/repo/pages/billing/_plugin/BillPayment.vue").then(m => m.default || m)
  },
  {
    name: "billing-_plugin-BillStatic",
    path: "/billing/_plugin/BillStatic",
    component: () => import("/opt/buildhome/repo/pages/billing/_plugin/BillStatic.vue").then(m => m.default || m)
  },
  {
    name: "billing-_plugin-Order",
    path: "/billing/_plugin/Order",
    component: () => import("/opt/buildhome/repo/pages/billing/_plugin/Order.vue").then(m => m.default || m)
  },
  {
    name: "billing-order-order",
    path: "/billing/order/:order()",
    meta: _91order_93lgTSrdr4irMeta || {},
    component: () => import("/opt/buildhome/repo/pages/billing/order/[order].vue").then(m => m.default || m)
  },
  {
    name: "billing-order-cart",
    path: "/billing/order/cart",
    meta: cartmlZ8y0ESmPMeta || {},
    component: () => import("/opt/buildhome/repo/pages/billing/order/cart.vue").then(m => m.default || m)
  },
  {
    name: "billing-order-checkout",
    path: "/billing/order/checkout",
    meta: checkoutIDaLEr8sLjMeta || {},
    component: () => import("/opt/buildhome/repo/pages/billing/order/checkout.vue").then(m => m.default || m)
  },
  {
    name: "billing-pay",
    path: "/billing/pay",
    meta: payiAGAQMY9h4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/billing/pay.vue").then(m => m.default || m)
  },
  {
    name: "billing-payment-_slug",
    path: "/billing/payment/_slug",
    meta: _slugWs41MlLQobMeta || {},
    component: () => import("/opt/buildhome/repo/pages/billing/payment/_slug.vue").then(m => m.default || m)
  },
  {
    name: "billing-payment-slug",
    path: "/billing/payment/:slug()",
    meta: _91slug_9377mCZIeR2CMeta || {},
    component: () => import("/opt/buildhome/repo/pages/billing/payment/[slug].vue").then(m => m.default || m)
  },
  {
    name: "billing-payment",
    path: "/billing/payment",
    meta: indexVbl1ygM7P4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/billing/payment/index.vue").then(m => m.default || m)
  },
  {
    name: "billing-resource-PromptPay",
    path: "/billing/resource/PromptPay",
    component: () => import("/opt/buildhome/repo/pages/billing/resource/PromptPay.vue").then(m => m.default || m)
  },
  {
    name: "billing-resource-Step",
    path: "/billing/resource/Step",
    component: () => import("/opt/buildhome/repo/pages/billing/resource/Step.vue").then(m => m.default || m)
  },
  {
    name: "billing-resource-UploadSlip",
    path: "/billing/resource/UploadSlip",
    component: () => import("/opt/buildhome/repo/pages/billing/resource/UploadSlip.vue").then(m => m.default || m)
  },
  {
    name: "billing-subscribe-order",
    path: "/billing/subscribe/:order()",
    component: () => import("/opt/buildhome/repo/pages/billing/subscribe/[order].vue").then(m => m.default || m)
  },
  {
    name: "billing-thankyou",
    path: "/billing/thankyou",
    meta: thankyouDcwB4TAk3nMeta || {},
    component: () => import("/opt/buildhome/repo/pages/billing/thankyou.vue").then(m => m.default || m)
  },
  {
    name: "calendar-calendar",
    path: "/calendar/:calendar()",
    component: () => import("/opt/buildhome/repo/pages/calendar/[calendar].vue").then(m => m.default || m)
  },
  {
    name: "calendar-Calendar",
    path: "/calendar/Calendar",
    component: () => import("/opt/buildhome/repo/pages/calendar/Calendar.vue").then(m => m.default || m)
  },
  {
    name: "calendar-email",
    path: "/calendar/email",
    component: () => import("/opt/buildhome/repo/pages/calendar/email.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/calendar",
    component: () => import("/opt/buildhome/repo/pages/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: "certification-find",
    path: "/certification/find",
    component: () => import("/opt/buildhome/repo/pages/certification/find.vue").then(m => m.default || m)
  },
  {
    name: "cluster-dashboard",
    path: "/cluster/dashboard",
    component: () => import("/opt/buildhome/repo/pages/cluster/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "cluster-filemanager",
    path: "/cluster/filemanager",
    component: () => import("/opt/buildhome/repo/pages/cluster/filemanager.vue").then(m => m.default || m)
  },
  {
    name: "cluster",
    path: "/cluster",
    component: () => import("/opt/buildhome/repo/pages/cluster/index.vue").then(m => m.default || m)
  },
  {
    name: "cluster-liff",
    path: "/cluster/liff",
    meta: liffMjZTHzIIv3Meta || {},
    component: () => import("/opt/buildhome/repo/pages/cluster/liff.vue").then(m => m.default || m)
  },
  {
    name: "cluster-operating_model",
    path: "/cluster/operating_model",
    component: () => import("/opt/buildhome/repo/pages/cluster/operating_model/index.vue").then(m => m.default || m)
  },
  {
    name: "cluster-operating_model-new",
    path: "/cluster/operating_model/new",
    component: () => import("/opt/buildhome/repo/pages/cluster/operating_model/new.vue").then(m => m.default || m)
  },
  {
    name: "cluster-order",
    path: "/cluster/order",
    component: () => import("/opt/buildhome/repo/pages/cluster/order.vue").then(m => m.default || m)
  },
  {
    name: "cluster-panel",
    path: "/cluster/panel",
    meta: panelNJeoZkOy75Meta || {},
    component: () => import("/opt/buildhome/repo/pages/cluster/panel.vue").then(m => m.default || m)
  },
  {
    name: "cluster-pos",
    path: "/cluster/pos",
    meta: posmVQbtWO1tPMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cluster/pos.vue").then(m => m.default || m),
    children: [
  {
    name: "cluster-pos-BillingSection",
    path: "BillingSection",
    component: () => import("/opt/buildhome/repo/pages/cluster/pos/BillingSection.vue").then(m => m.default || m)
  },
  {
    name: "cluster-pos-Calculator",
    path: "Calculator",
    component: () => import("/opt/buildhome/repo/pages/cluster/pos/Calculator.vue").then(m => m.default || m)
  },
  {
    name: "cluster-pos-CheckoutSection",
    path: "CheckoutSection",
    component: () => import("/opt/buildhome/repo/pages/cluster/pos/CheckoutSection.vue").then(m => m.default || m)
  },
  {
    name: "cluster-pos-Order",
    path: "Order",
    component: () => import("/opt/buildhome/repo/pages/cluster/pos/Order.vue").then(m => m.default || m)
  },
  {
    name: "cluster-pos-ProductSection",
    path: "ProductSection",
    component: () => import("/opt/buildhome/repo/pages/cluster/pos/ProductSection.vue").then(m => m.default || m)
  },
  {
    name: "cluster-pos-StockManager",
    path: "StockManager",
    component: () => import("/opt/buildhome/repo/pages/cluster/pos/StockManager.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "cluster-products",
    path: "/cluster/products",
    component: () => import("/opt/buildhome/repo/pages/cluster/products/index.vue").then(m => m.default || m)
  },
  {
    name: "cluster-scan",
    path: "/cluster/scan",
    component: () => import("/opt/buildhome/repo/pages/cluster/scan.vue").then(m => m.default || m)
  },
  {
    name: "cluster-vendor",
    path: "/cluster/vendor",
    component: () => import("/opt/buildhome/repo/pages/cluster/vendor.vue").then(m => m.default || m)
  },
  {
    name: "cms-blog-category-post",
    path: "/cms-blog/:category()/:post()",
    component: () => import("/opt/buildhome/repo/pages/cms-blog/[category]/[post].vue").then(m => m.default || m)
  },
  {
    name: "cms-blog-slug",
    path: "/cms-blog/:slug()",
    component: () => import("/opt/buildhome/repo/pages/cms-blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "cms-blog",
    path: "/cms-blog",
    component: () => import("/opt/buildhome/repo/pages/cms-blog/index.vue").then(m => m.default || m)
  },
  {
    name: "cms-_component-page-admin",
    path: "/cms/_component/page/admin",
    component: () => import("/opt/buildhome/repo/pages/cms/_component/page/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "cms-_component-page-guest",
    path: "/cms/_component/page/guest",
    component: () => import("/opt/buildhome/repo/pages/cms/_component/page/guest/index.vue").then(m => m.default || m)
  },
  {
    name: "cms-_component-page-manager",
    path: "/cms/_component/page/manager",
    component: () => import("/opt/buildhome/repo/pages/cms/_component/page/manager/index.vue").then(m => m.default || m)
  },
  {
    name: "cms-_component-page-user",
    path: "/cms/_component/page/user",
    component: () => import("/opt/buildhome/repo/pages/cms/_component/page/user/index.vue").then(m => m.default || m)
  },
  {
    name: "cms-page-slug",
    path: "/cms/page/:slug()",
    component: () => import("/opt/buildhome/repo/pages/cms/page/[slug].vue").then(m => m.default || m)
  },
  {
    name: "config",
    path: "/config",
    component: () => import("/opt/buildhome/repo/pages/config.vue").then(m => m.default || m)
  },
  {
    name: "custom",
    path: "/custom",
    meta: customhfyaHdprHdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/custom.vue").then(m => m.default || m)
  },
  {
    name: "demo-element",
    path: "/demo/element",
    component: () => import("/opt/buildhome/repo/pages/demo/element.vue").then(m => m.default || m)
  },
  {
    name: "doa-register-68",
    path: "/doa-register-68",
    component: () => import("/opt/buildhome/repo/pages/doa-register-68.vue").then(m => m.default || m)
  },
  {
    name: "event-_plugin-manage_event",
    path: "/event/_plugin/manage_event",
    component: () => import("/opt/buildhome/repo/pages/event/_plugin/manage_event.vue").then(m => m.default || m)
  },
  {
    name: "event-_plugin-manage_gift",
    path: "/event/_plugin/manage_gift",
    component: () => import("/opt/buildhome/repo/pages/event/_plugin/manage_gift.vue").then(m => m.default || m)
  },
  {
    name: "event-_plugin-manage_influ",
    path: "/event/_plugin/manage_influ",
    component: () => import("/opt/buildhome/repo/pages/event/_plugin/manage_influ.vue").then(m => m.default || m)
  },
  {
    name: "event-_plugin-manager",
    path: "/event/_plugin/manager",
    component: () => import("/opt/buildhome/repo/pages/event/_plugin/manager.vue").then(m => m.default || m)
  },
  {
    name: "event-_plugin-module-event",
    path: "/event/_plugin/module/event",
    component: () => import("/opt/buildhome/repo/pages/event/_plugin/module/event.vue").then(m => m.default || m)
  },
  {
    name: "event-_plugin-module-gift_list",
    path: "/event/_plugin/module/gift_list",
    component: () => import("/opt/buildhome/repo/pages/event/_plugin/module/gift_list.vue").then(m => m.default || m)
  },
  {
    name: "event-_plugin-module-gift",
    path: "/event/_plugin/module/gift",
    component: () => import("/opt/buildhome/repo/pages/event/_plugin/module/gift.vue").then(m => m.default || m)
  },
  {
    name: "event-_plugin-module-influencer",
    path: "/event/_plugin/module/influencer",
    component: () => import("/opt/buildhome/repo/pages/event/_plugin/module/influencer.vue").then(m => m.default || m)
  },
  {
    name: "event-_plugin-module-package",
    path: "/event/_plugin/module/package",
    component: () => import("/opt/buildhome/repo/pages/event/_plugin/module/package.vue").then(m => m.default || m)
  },
  {
    name: "event-slug",
    path: "/event/:slug()",
    component: () => import("/opt/buildhome/repo/pages/event/[slug].vue").then(m => m.default || m)
  },
  {
    name: "event",
    path: "/event",
    component: () => import("/opt/buildhome/repo/pages/event/index.vue").then(m => m.default || m)
  },
  {
    name: "external-storage-file-code",
    path: "/external/storage/file/:code()",
    meta: _91code_93EpwaDuunx9Meta || {},
    component: () => import("/opt/buildhome/repo/pages/external/storage/file/[code].vue").then(m => m.default || m)
  },
  {
    name: "external-storage-folder-code",
    path: "/external/storage/folder/:code()",
    meta: _91code_93wJHSQGlyFYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/external/storage/folder/[code].vue").then(m => m.default || m)
  },
  {
    name: "hora-baseData",
    path: "/hora/baseData",
    component: () => import("/opt/buildhome/repo/pages/hora/baseData.js").then(m => m.default || m)
  },
  {
    name: "hora-calculationMethods",
    path: "/hora/calculationMethods",
    component: () => import("/opt/buildhome/repo/pages/hora/calculationMethods.js").then(m => m.default || m)
  },
  {
    name: "hora",
    path: "/hora",
    meta: indexc4zgumGKUTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/hora/index.vue").then(m => m.default || m)
  },
  {
    name: "hora-rakkana",
    path: "/hora/rakkana",
    component: () => import("/opt/buildhome/repo/pages/hora/rakkana.vue").then(m => m.default || m)
  },
  {
    name: "hora-static",
    path: "/hora/static",
    component: () => import("/opt/buildhome/repo/pages/hora/static.vue").then(m => m.default || m)
  },
  {
    name: "idea",
    path: "/idea",
    component: () => import("/opt/buildhome/repo/pages/idea/index.vue").then(m => m.default || m)
  },
  {
    name: "idea-LinkableObjects",
    path: "/idea/LinkableObjects",
    component: () => import("/opt/buildhome/repo/pages/idea/LinkableObjects.vue").then(m => m.default || m)
  },
  {
    name: "idea-LinkableObjectsWithStatic",
    path: "/idea/LinkableObjectsWithStatic",
    component: () => import("/opt/buildhome/repo/pages/idea/LinkableObjectsWithStatic.vue").then(m => m.default || m)
  },
  {
    name: "idea-StaticGridWithLinkage",
    path: "/idea/StaticGridWithLinkage",
    component: () => import("/opt/buildhome/repo/pages/idea/StaticGridWithLinkage.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "influencer-slug",
    path: "/influencer/:slug()",
    component: () => import("/opt/buildhome/repo/pages/influencer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "influencer-gift",
    path: "/influencer/gift",
    component: () => import("/opt/buildhome/repo/pages/influencer/gift.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_component-assessment-guest",
    path: "/lesson/_component/assessment/guest",
    component: () => import("/opt/buildhome/repo/pages/lesson/_component/assessment/guest/index.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_component-calendar-guest",
    path: "/lesson/_component/calendar/guest",
    component: () => import("/opt/buildhome/repo/pages/lesson/_component/calendar/guest/index.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_component-calendar-user",
    path: "/lesson/_component/calendar/user",
    component: () => import("/opt/buildhome/repo/pages/lesson/_component/calendar/user/index.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_component-detail-admin",
    path: "/lesson/_component/detail/admin",
    component: () => import("/opt/buildhome/repo/pages/lesson/_component/detail/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_component-detail-guest",
    path: "/lesson/_component/detail/guest",
    component: () => import("/opt/buildhome/repo/pages/lesson/_component/detail/guest/index.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_component-detail-manager",
    path: "/lesson/_component/detail/manager",
    component: () => import("/opt/buildhome/repo/pages/lesson/_component/detail/manager/index.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_component-detail-user",
    path: "/lesson/_component/detail/user",
    component: () => import("/opt/buildhome/repo/pages/lesson/_component/detail/user/index.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_component-enroll-guest",
    path: "/lesson/_component/enroll/guest",
    component: () => import("/opt/buildhome/repo/pages/lesson/_component/enroll/guest/index.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_component-index-admin",
    path: "/lesson/_component/admin",
    component: () => import("/opt/buildhome/repo/pages/lesson/_component/index/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_component-index-guest",
    path: "/lesson/_component/guest",
    component: () => import("/opt/buildhome/repo/pages/lesson/_component/index/guest/index.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_component-index-manager",
    path: "/lesson/_component/manager",
    component: () => import("/opt/buildhome/repo/pages/lesson/_component/index/manager/index.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_component-index-user",
    path: "/lesson/_component/user",
    component: () => import("/opt/buildhome/repo/pages/lesson/_component/index/user/index.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_component-play-guest",
    path: "/lesson/_component/play/guest",
    component: () => import("/opt/buildhome/repo/pages/lesson/_component/play/guest/index.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-AskContent",
    path: "/lesson/_plugin/AskContent",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/AskContent.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-assessment-Exam",
    path: "/lesson/_plugin/assessment/Exam",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/assessment/Exam.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-assessment-Intro",
    path: "/lesson/_plugin/assessment/Intro",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/assessment/Intro.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-assessment-Result",
    path: "/lesson/_plugin/assessment/Result",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/assessment/Result.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-assessment-webcam",
    path: "/lesson/_plugin/assessment/webcam",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/assessment/webcam.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-builder-module-authen",
    path: "/lesson/_plugin/builder/module/authen",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/builder/module/authen.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-builder-module-enroll",
    path: "/lesson/_plugin/builder/module/enroll",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/builder/module/enroll.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-BypassPlayer",
    path: "/lesson/_plugin/BypassPlayer",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/BypassPlayer.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-calendar-Modal",
    path: "/lesson/_plugin/calendar/Modal",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/calendar/Modal.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-certification-Render",
    path: "/lesson/_plugin/certification/Render",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/certification/Render.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-Curriculum",
    path: "/lesson/_plugin/Curriculum",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/Curriculum.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-Description",
    path: "/lesson/_plugin/Description",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/Description.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-EnrollButton",
    path: "/lesson/_plugin/EnrollButton",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/EnrollButton.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-ExamPlayer",
    path: "/lesson/_plugin/ExamPlayer",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/ExamPlayer.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-IdleChecker",
    path: "/lesson/_plugin/IdleChecker",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/IdleChecker.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-Player",
    path: "/lesson/_plugin/Player",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/Player.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-PlayerDemo",
    path: "/lesson/_plugin/PlayerDemo",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/PlayerDemo.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-PlayerItem",
    path: "/lesson/_plugin/PlayerItem",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/PlayerItem.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-Price",
    path: "/lesson/_plugin/Price",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/Price.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-ProxyPlayer",
    path: "/lesson/_plugin/ProxyPlayer",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/ProxyPlayer.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-RatingModal",
    path: "/lesson/_plugin/RatingModal",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/RatingModal.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-scheduleTable",
    path: "/lesson/_plugin/scheduleTable",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/scheduleTable.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-stun-live",
    path: "/lesson/_plugin/stun/live",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/stun/live.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-stun-rtc",
    path: "/lesson/_plugin/stun/rtc",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/stun/rtc.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-stun-view",
    path: "/lesson/_plugin/stun/view",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/stun/view.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-Survey",
    path: "/lesson/_plugin/Survey",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/Survey.vue").then(m => m.default || m)
  },
  {
    name: "lesson-_plugin-webcam",
    path: "/lesson/_plugin/webcam",
    component: () => import("/opt/buildhome/repo/pages/lesson/_plugin/webcam.vue").then(m => m.default || m)
  },
  {
    name: "lesson-assessment-lesson-exam",
    path: "/lesson/assessment/:lesson()/:exam()",
    component: () => import("/opt/buildhome/repo/pages/lesson/assessment/[lesson]/[exam].vue").then(m => m.default || m)
  },
  {
    name: "lesson-calendar-year",
    path: "/lesson/calendar/:year()",
    component: () => import("/opt/buildhome/repo/pages/lesson/calendar/[year].vue").then(m => m.default || m)
  },
  {
    name: "lesson-certification-slug-cert",
    path: "/lesson/certification/:slug()/:cert()",
    component: () => import("/opt/buildhome/repo/pages/lesson/certification/[slug]/[cert].vue").then(m => m.default || m)
  },
  {
    name: "lesson-certification-slug",
    path: "/lesson/certification/:slug()",
    component: () => import("/opt/buildhome/repo/pages/lesson/certification/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "lesson-certification-public-slug",
    path: "/lesson/certification/public/:slug()",
    component: () => import("/opt/buildhome/repo/pages/lesson/certification/public/[slug].vue").then(m => m.default || m)
  },
  {
    name: "lesson-certification-search",
    path: "/lesson/certification/search",
    component: () => import("/opt/buildhome/repo/pages/lesson/certification/search.vue").then(m => m.default || m)
  },
  {
    name: "lesson-detail-slug",
    path: "/lesson/detail/:slug()",
    component: () => import("/opt/buildhome/repo/pages/lesson/detail/[slug].vue").then(m => m.default || m)
  },
  {
    name: "lesson-enroll-slug",
    path: "/lesson/enroll/:slug()",
    component: () => import("/opt/buildhome/repo/pages/lesson/enroll/[slug].vue").then(m => m.default || m)
  },
  {
    name: "lesson",
    path: "/lesson",
    component: () => import("/opt/buildhome/repo/pages/lesson/index.vue").then(m => m.default || m)
  },
  {
    name: "lesson-play-lesson-slug",
    path: "/lesson/play/:lesson()/:slug()",
    component: () => import("/opt/buildhome/repo/pages/lesson/play/[lesson]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "lesson-stun-live",
    path: "/lesson/stun/live",
    component: () => import("/opt/buildhome/repo/pages/lesson/stun/live.vue").then(m => m.default || m)
  },
  {
    name: "lesson-stun-view",
    path: "/lesson/stun/view",
    component: () => import("/opt/buildhome/repo/pages/lesson/stun/view.vue").then(m => m.default || m)
  },
  {
    name: "project-group_id-project_id",
    path: "/project/:group_id()/:project_id()",
    component: () => import("/opt/buildhome/repo/pages/project/[group_id]/[project_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "project-group_id",
    path: "/project/:group_id()",
    component: () => import("/opt/buildhome/repo/pages/project/[group_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "project-admin-agenda",
    path: "/project/admin/agenda",
    component: () => import("/opt/buildhome/repo/pages/project/admin/agenda.vue").then(m => m.default || m)
  },
  {
    name: "project-admin-calendar",
    path: "/project/admin/calendar",
    component: () => import("/opt/buildhome/repo/pages/project/admin/calendar.vue").then(m => m.default || m)
  },
  {
    name: "project-admin-CommentItem",
    path: "/project/admin/CommentItem",
    component: () => import("/opt/buildhome/repo/pages/project/admin/CommentItem.vue").then(m => m.default || m)
  },
  {
    name: "project-admin-dashboard",
    path: "/project/admin/dashboard",
    component: () => import("/opt/buildhome/repo/pages/project/admin/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "project-admin-detail",
    path: "/project/admin/detail",
    component: () => import("/opt/buildhome/repo/pages/project/admin/detail.vue").then(m => m.default || m)
  },
  {
    name: "project-admin-gantt",
    path: "/project/admin/gantt",
    component: () => import("/opt/buildhome/repo/pages/project/admin/gantt.vue").then(m => m.default || m)
  },
  {
    name: "project-admin",
    path: "/project/admin",
    component: () => import("/opt/buildhome/repo/pages/project/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "project-admin-ModalCategory",
    path: "/project/admin/ModalCategory",
    component: () => import("/opt/buildhome/repo/pages/project/admin/ModalCategory.vue").then(m => m.default || m)
  },
  {
    name: "project-admin-ModalStatus",
    path: "/project/admin/ModalStatus",
    component: () => import("/opt/buildhome/repo/pages/project/admin/ModalStatus.vue").then(m => m.default || m)
  },
  {
    name: "project-admin-ModalTask",
    path: "/project/admin/ModalTask",
    component: () => import("/opt/buildhome/repo/pages/project/admin/ModalTask.vue").then(m => m.default || m)
  },
  {
    name: "project-admin-ProjectFolder",
    path: "/project/admin/ProjectFolder",
    component: () => import("/opt/buildhome/repo/pages/project/admin/ProjectFolder.vue").then(m => m.default || m)
  },
  {
    name: "project-admin-table",
    path: "/project/admin/table",
    component: () => import("/opt/buildhome/repo/pages/project/admin/table.vue").then(m => m.default || m)
  },
  {
    name: "project-admin-task",
    path: "/project/admin/task",
    component: () => import("/opt/buildhome/repo/pages/project/admin/task.vue").then(m => m.default || m)
  },
  {
    name: "project-admin-todo",
    path: "/project/admin/todo",
    component: () => import("/opt/buildhome/repo/pages/project/admin/todo.vue").then(m => m.default || m)
  },
  {
    name: "project",
    path: "/project",
    component: () => import("/opt/buildhome/repo/pages/project/index.vue").then(m => m.default || m)
  },
  {
    name: "scan",
    path: "/scan",
    component: () => import("/opt/buildhome/repo/pages/scan.vue").then(m => m.default || m)
  },
  {
    name: "shop-_component-cart-guest",
    path: "/shop/_component/cart/guest",
    component: () => import("/opt/buildhome/repo/pages/shop/_component/cart/guest/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-_component-cart-user",
    path: "/shop/_component/cart/user",
    component: () => import("/opt/buildhome/repo/pages/shop/_component/cart/user/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-_component-categorie-guest",
    path: "/shop/_component/categorie/guest",
    component: () => import("/opt/buildhome/repo/pages/shop/_component/categorie/guest/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-_component-categorie-user",
    path: "/shop/_component/categorie/user",
    component: () => import("/opt/buildhome/repo/pages/shop/_component/categorie/user/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-_component-categories-guest",
    path: "/shop/_component/categories/guest",
    component: () => import("/opt/buildhome/repo/pages/shop/_component/categories/guest/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-_component-categories-user",
    path: "/shop/_component/categories/user",
    component: () => import("/opt/buildhome/repo/pages/shop/_component/categories/user/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-_component-checkout-guest",
    path: "/shop/_component/checkout/guest",
    component: () => import("/opt/buildhome/repo/pages/shop/_component/checkout/guest/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-_component-checkout-user",
    path: "/shop/_component/checkout/user",
    component: () => import("/opt/buildhome/repo/pages/shop/_component/checkout/user/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-_component-product-guest",
    path: "/shop/_component/product/guest",
    component: () => import("/opt/buildhome/repo/pages/shop/_component/product/guest/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-_component-product-user",
    path: "/shop/_component/product/user",
    component: () => import("/opt/buildhome/repo/pages/shop/_component/product/user/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-_component-products-guest",
    path: "/shop/_component/products/guest",
    component: () => import("/opt/buildhome/repo/pages/shop/_component/products/guest/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-_component-products-user",
    path: "/shop/_component/products/user",
    component: () => import("/opt/buildhome/repo/pages/shop/_component/products/user/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-_component-thankyou-guest",
    path: "/shop/_component/thankyou/guest",
    component: () => import("/opt/buildhome/repo/pages/shop/_component/thankyou/guest/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-_component-thankyou-user",
    path: "/shop/_component/thankyou/user",
    component: () => import("/opt/buildhome/repo/pages/shop/_component/thankyou/user/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-_plugin-product-gallery",
    path: "/shop/_plugin/product/gallery",
    component: () => import("/opt/buildhome/repo/pages/shop/_plugin/product/gallery.vue").then(m => m.default || m)
  },
  {
    name: "shop-_plugin-product-rating",
    path: "/shop/_plugin/product/rating",
    component: () => import("/opt/buildhome/repo/pages/shop/_plugin/product/rating.vue").then(m => m.default || m)
  },
  {
    name: "shop-_plugin-product-swatch",
    path: "/shop/_plugin/product/swatch",
    component: () => import("/opt/buildhome/repo/pages/shop/_plugin/product/swatch.vue").then(m => m.default || m)
  },
  {
    name: "shop-cart",
    path: "/shop/cart",
    component: () => import("/opt/buildhome/repo/pages/shop/cart.vue").then(m => m.default || m)
  },
  {
    name: "shop-categorie-code",
    path: "/shop/categorie/:code()",
    component: () => import("/opt/buildhome/repo/pages/shop/categorie/[code].vue").then(m => m.default || m)
  },
  {
    name: "shop-categories",
    path: "/shop/categories",
    component: () => import("/opt/buildhome/repo/pages/shop/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-checkout",
    path: "/shop/checkout",
    component: () => import("/opt/buildhome/repo/pages/shop/checkout.vue").then(m => m.default || m)
  },
  {
    name: "shop-product-sku",
    path: "/shop/product/:sku()",
    component: () => import("/opt/buildhome/repo/pages/shop/product/[sku].vue").then(m => m.default || m)
  },
  {
    name: "shop-products",
    path: "/shop/products",
    component: () => import("/opt/buildhome/repo/pages/shop/products/index.vue").then(m => m.default || m)
  },
  {
    name: "shop-thankyou-order",
    path: "/shop/thankyou/:order()",
    component: () => import("/opt/buildhome/repo/pages/shop/thankyou/[order].vue").then(m => m.default || m)
  },
  {
    name: "user-_plugin-Login",
    path: "/user/_plugin/Login",
    component: () => import("/opt/buildhome/repo/pages/user/_plugin/Login.vue").then(m => m.default || m)
  },
  {
    name: "user-activate",
    path: "/user/activate",
    component: () => import("/opt/buildhome/repo/pages/user/activate.vue").then(m => m.default || m)
  },
  {
    name: "user-authen-line-callback",
    path: "/user/authen/line/callback",
    component: () => import("/opt/buildhome/repo/pages/user/authen/line/callback.vue").then(m => m.default || m)
  },
  {
    name: "user-authen-line",
    path: "/user/authen/line",
    component: () => import("/opt/buildhome/repo/pages/user/authen/line/index.vue").then(m => m.default || m)
  },
  {
    name: "user-browser",
    path: "/user/browser",
    component: () => import("/opt/buildhome/repo/pages/user/browser.vue").then(m => m.default || m)
  },
  {
    name: "user-bypass-slug",
    path: "/user/bypass/:slug()",
    component: () => import("/opt/buildhome/repo/pages/user/bypass/[slug].vue").then(m => m.default || m)
  },
  {
    name: "user-dashboard",
    path: "/user/dashboard",
    component: () => import("/opt/buildhome/repo/pages/user/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "user-device_debug",
    path: "/user/device_debug",
    component: () => import("/opt/buildhome/repo/pages/user/device_debug.vue").then(m => m.default || m)
  },
  {
    name: "user-filemanager_2",
    path: "/user/filemanager_2",
    component: () => import("/opt/buildhome/repo/pages/user/filemanager_2.vue").then(m => m.default || m)
  },
  {
    name: "user-filemanager",
    path: "/user/filemanager",
    component: () => import("/opt/buildhome/repo/pages/user/filemanager.vue").then(m => m.default || m)
  },
  {
    name: "user",
    path: "/user",
    component: () => import("/opt/buildhome/repo/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-login",
    path: "/user/login",
    component: () => import("/opt/buildhome/repo/pages/user/login.vue").then(m => m.default || m)
  },
  {
    name: "user-message",
    path: "/user/message",
    component: () => import("/opt/buildhome/repo/pages/user/message.vue").then(m => m.default || m)
  },
  {
    name: "user-pos",
    path: "/user/pos",
    meta: posDypze5mMMoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/user/pos.vue").then(m => m.default || m),
    children: [
  {
    name: "user-pos-BillingSection",
    path: "BillingSection",
    component: () => import("/opt/buildhome/repo/pages/user/pos/BillingSection.vue").then(m => m.default || m)
  },
  {
    name: "user-pos-Calculator",
    path: "Calculator",
    component: () => import("/opt/buildhome/repo/pages/user/pos/Calculator.vue").then(m => m.default || m)
  },
  {
    name: "user-pos-CheckoutSection",
    path: "CheckoutSection",
    component: () => import("/opt/buildhome/repo/pages/user/pos/CheckoutSection.vue").then(m => m.default || m)
  },
  {
    name: "user-pos-Order",
    path: "Order",
    component: () => import("/opt/buildhome/repo/pages/user/pos/Order.vue").then(m => m.default || m)
  },
  {
    name: "user-pos-ProductSection",
    path: "ProductSection",
    component: () => import("/opt/buildhome/repo/pages/user/pos/ProductSection.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-profile",
    path: "/user/profile",
    component: () => import("/opt/buildhome/repo/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: "user-qrcode",
    path: "/user/qrcode",
    meta: qrcodeNZgdfcemnfMeta || {},
    component: () => import("/opt/buildhome/repo/pages/user/qrcode.vue").then(m => m.default || m)
  },
  {
    name: "user-recovery",
    path: "/user/recovery",
    component: () => import("/opt/buildhome/repo/pages/user/recovery.vue").then(m => m.default || m)
  },
  {
    name: "user-register",
    path: "/user/register",
    component: () => import("/opt/buildhome/repo/pages/user/register.vue").then(m => m.default || m)
  },
  {
    name: "user-upload",
    path: "/user/upload",
    component: () => import("/opt/buildhome/repo/pages/user/upload.vue").then(m => m.default || m)
  },
  {
    name: "user-wallet",
    path: "/user/wallet",
    component: () => import("/opt/buildhome/repo/pages/user/wallet.vue").then(m => m.default || m)
  }
]