<template>
  <div>
    <div v-if="pending" class="loading-state">
      <p>Loading data, please wait...</p>
    </div>
    <div v-else>

      <HostPanel v-if="isLocalhost" v-model:searchQuery="searchQuery" 
      :isLocalhost="isLocalhost" :isPanelVisible="isPanelVisible" 
      :dropdownVisible="dropdownVisible" :selectedOption="selectedOption" 
      :hostname="hostname" :hostsData="hostsData" 
      @toggle-panel="isPanelVisible = !isPanelVisible"   
      @toggle-dropdown="dropdownVisible = !dropdownVisible" 
      @select-option="selectOption" 
      @reset-host="removeLocalhostCookie"
      />
      
      <button  v-if="profile" @click="toggleUserPanelFooter" class="toggle-footer-btn text-xs border-t border-l border-r border-gray-200 z-50" :style="{ bottom: isUserPanelVisible ? '24px' : '0' }">
        <i :class="isUserPanelVisible ? 'fas fa-chevron-down' : 'fas fa-chevron-up'"></i> user info
      </button>

      <div class="user-panel-footer bg-white text-opacity-70 text-black border-t border-gray-200 fixed w-full bottom-0 z-40 text-xs px-2 py-1" v-if="isUserPanelVisible && profile">
        <div class="user-data-columns">
          <div class="user-data-column">
            <p><i class="fas fa-user"></i> <span class="text-xs text-gray-400">{{ profile.firstname }} {{ profile.lastname }}</span></p>
          </div>
          <div class="user-data-column">
            <p><i class="fas fa-globe"></i> <span class="text-xs text-gray-400">{{ profile.browserName }}</span></p>
          </div>
          <div class="user-data-column">
            <p><i class="fas fa-desktop text-xs"></i> <span class="text-xs text-gray-400">{{ profile.osName }}</span></p>
          </div>
        </div>
      </div>

      <!-- <DebugPanel
      v-if="isLocalhost"
      :componentKey="componentKey"
      :sessionValid="sessionValid"
      :hostname="hostname"
      :authTokenValue="authTokenValue"
      /> -->
      
      <NuxtLayout>
        <ContextMenu/>
        <NuxtPage :key="componentKey"/>
      </NuxtLayout>

      

    </div>
    
  </div>
</template>

<script setup>

/*
  การทำงานของ sessionValid ร่วมกับ componentKey
  -----------------------------------------------
  sessionValid:
  - เป็นตัวแปร reactive ที่ใช้ตรวจสอบสถานะการเข้าสู่ระบบ (session) โดยดึงค่าจาก composable `useSession`
  - ค่า sessionValid จะถูกกำหนดโดยฟังก์ชัน `refreshSession` ซึ่งเรียกใช้งาน `checkAuthToken` เพื่อตรวจสอบความถูกต้องของ session token
  
  componentKey:
  - ใช้เป็นตัวแปรสำหรับบังคับให้ component หลัก (root component) รีโหลดเมื่อมีการเปลี่ยนแปลงค่า
  - เชื่อมต่อกับ NuxtPage หรือ component หลักโดยใช้ `:key="componentKey"`

  กระบวนการทำงาน:
  - มีการใช้ watch เพื่อเฝ้าดูการเปลี่ยนแปลงของ sessionValid
  - เมื่อ sessionValid มีการเปลี่ยนแปลง (เช่น จาก true เป็น false หรือกลับกัน):
    1. componentKey จะถูกเพิ่มค่า (componentKey.value += 1)
    2. การเปลี่ยนค่า componentKey จะทำให้ root component รีโหลดใหม่
    3. การรีโหลด root component จะกระตุ้นให้ child component เช่น ContextMenu และ HostPanel โหลดใหม่ด้วย
  
  ประโยชน์:
  - การออกแบบนี้ช่วยให้ระบบสามารถรีโหลด component ได้อัตโนมัติเมื่อ session หมดอายุหรือเปลี่ยนสถานะ
  - ช่วยรักษาความปลอดภัยของข้อมูล และอัปเดตสถานะล่าสุดโดยไม่ต้องรีโหลดหน้าเว็บทั้งหมด
  
  ตัวอย่างโค้ดสำคัญ:
  watch(sessionValid, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      console.log(`componentKey:${componentKey.value} sessionValid changed, reloading component...`);
      componentKey.value += 1; // บังคับให้ root component รีโหลด
    }
  });
*/
import axios from 'axios';
import { useHead, useFetch, useRuntimeConfig, useCookie } from '#imports';
import { handleRequest } from '~/utils/handle.js';
import { provide, ref, onMounted } from 'vue';
import { useHistory } from '~/composables/useHistory';
const { recordHistory } = useHistory();

import ContextMenu from '~/components/plugin/ContextMenu.vue';
import HostPanel from '~/components/HostPanel.vue';
import DebugPanel from '~/components/DebugPanel.vue';

import { useSession } from '~/composables/useSession'; // Import the session composable
const { sessionValid, refreshSession } = useSession(); // Use session composable

import { handleFetch } from '~/utils/fetch.js';

// Retrieve token from the '_account' cookie
const authToken = useCookie('_account'); // Using the vue-composable's useCookie
// Make a computed reference for authToken
const authTokenValue = computed(() => !!authToken.value);

// State for dropdown
const dropdownVisible = ref(false);
const selectedOption = ref(null);
const profile = ref(null);
// Methods for dropdown
const toggleDropdown = () => {
  dropdownVisible.value = !dropdownVisible.value;
};

const loadEruda = () => {
  if (typeof window !== "undefined") {
    if (window.eruda) {
      // If Eruda is already loaded, just initialize and show it
      window.eruda.init();
      window.eruda.position({x: 10, y: 900});
      //window.eruda.show();
      return;
    }

    // Dynamically create script tag
    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/npm/eruda";
    script.async = true;
    script.onload = () => {
      // Ensure that Eruda is properly defined before using it
      if (window.eruda) {
        window.eruda.init();
        window.eruda.position({x: 10, y: 800});
        //window.eruda.show(); // Ensure this is called after Eruda is loaded
      } else {
        console.error("Eruda failed to load properly.");
      }
    };

    // Append to document
    document.head.appendChild(script);
  }
};
let requestConfig = null;


const configData = ref(null);
const hostsData = ref(null);
const themeData = ref(null);
const spaceData = ref(null);
const error = ref(null);
const pending = ref(true);
// Detect localhost environment
const isLocalhost = ref(false);
const isPanelVisible = ref(false);

const runtimeConfig = useRuntimeConfig();
let hostname = ref(null);

const componentKey = ref(0); // Key to trigger re-rendering
//console.log(`componentKey:${componentKey.value}`);
// Watch for changes in sessionValid
watch(sessionValid, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    //console.log(`componentKey:${componentKey.value} sessionValid changed, reloading component...`);
    componentKey.value += 1; // Increment key to reload the component
  }
});

const hostCookie = useCookie('_site', { maxAge: 900 });
const hostsCookie = useCookie('_hosts', { maxAge: 3600 });

if (process.server) {
  const nuxtApp = useNuxtApp();
  const req = nuxtApp.ssrContext?.event?.req;
  if (req) {
    hostname.value = req.headers.host?.split(':')[0] || 'frontend.cloudrestfulapi.com';
  }
} else if (process.client) {
  hostname.value = window.location.hostname.split(':')[0];
}

if (hostname.value === 'localhost' || hostname.value === '127.0.0.1'|| hostname.value.includes('ngrok-free.app')) {
  isLocalhost.value = true;
  hostname.value = 'doa.fti.or.th';
}

if (hostname.value === 'frontend.cloudrestfulapi.com') {
  hostname.value = 'fti.academy';
}

// Add a reactive property for search query
const searchQuery = ref('');

// Compute the filtered options based on the search query
const filteredHosts = computed(() => {
  if (!searchQuery.value) {
    // Show last 10 items if no search query
    return hostsData.value.slice(-14);
  }
  // Filter hosts based on the search query
  return hostsData.value
    .filter(option => 
      option.siteName.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      option.hostname.toLowerCase().includes(searchQuery.value.toLowerCase())
    )
    .slice(-14); // Limit to the last 10 items matching the query
});

// Update selectOption function
const selectOption = (option) => {
  selectedOption.value = option;
  dropdownVisible.value = false; // Close dropdown after selection
  // Store selected hostname in _localhost cookie
  const localhostCookie = useCookie('_localhost', { maxAge: 3600 });
  localhostCookie.value = option.hostname;
  // Set hostname to the value in _localhost cookie if it exists
  hostname.value = localhostCookie.value || hostname.value;
  // Clear hostCookie to refresh configuration
  const hostCookie = useCookie('_site', { maxAge: 900 });
  hostCookie.value = null;
  // Force reload with new hostname
  location.reload();
};

const removeLocalhostCookie = () => {
  const localhostCookie = useCookie('_localhost', { maxAge: 3600 });
  const siteCookie = useCookie('_site', { maxAge: 900 });
  localhostCookie.value = null; // Clear _localhost cookie
  siteCookie.value = null; // Clear _site cookie
  location.reload(); // Reload the page
};

// ฟังก์ชันเพื่อดึงชื่อเบราว์เซอร์และระบบปฏิบัติการ
const getBrowserAndOS = () => {
  const userAgent = navigator.userAgent;
  let browserName, osName;

  // ตรวจสอบชื่อเบราว์เซอร์
  if (userAgent.indexOf("Chrome") > -1) {
    browserName = "Chrome";
  } else if (userAgent.indexOf("Firefox") > -1) {
    browserName = "Firefox";
  } else if (userAgent.indexOf("Safari") > -1) {
    browserName = "Safari";
  } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) {
    browserName = "Internet Explorer";
  } else {
    browserName = "Unknown";
  }

  // ตรวจสอบชื่อระบบปฏิบัติการ
  if (userAgent.indexOf("Win") > -1) {
    osName = "Windows";
  } else if (userAgent.indexOf("Mac") > -1) {
    osName = "MacOS";
  } else if (userAgent.indexOf("X11") > -1 || userAgent.indexOf("Linux") > -1) {
    osName = "Linux";
  } else {
    osName = "Unknown OS";
  }

  return { browserName, osName };
};

const isUserPanelVisible = ref(false); // สถานะการแสดงผลของ user-panel-footer

const toggleUserPanelFooter = () => {
  isUserPanelVisible.value = !isUserPanelVisible.value; // สลับสถานะการแสดงผล
};

requestConfig = handleRequest('DU1eYMDG7j8yb199YDPg4', isLocalhost.value ? 'localhost' : 'production');
//console.log('requestConfig', requestConfig);

try {
  // Check if _localhost cookie has data and use it for hostname
  const localhostCookie = useCookie('_localhost', { maxAge: 3600 });
  if (localhostCookie.value) {
    hostname.value = localhostCookie.value;
  }

  if (!hostCookie.value) {
    const { data: hostReturn } = await useFetch(`${requestConfig.baseURL}/api/getHost?hostname=${hostname.value}`, {
      headers: requestConfig.headers,
    });

    configData.value  = hostReturn.value.hostData;
    hostsData.value   = hostReturn.value.hosts;
    hostCookie.value  = configData.value;

    if (isLocalhost.value) {
      hostsCookie.value = hostsData.value;
    }
  } else {
    configData.value = hostCookie.value;

    if (isLocalhost.value && hostsCookie.value) {
      hostsData.value = hostsCookie.value;
    }
  }

  useHead({
    titleTemplate: `%s / ${configData.value.siteName}`,
    link: [{
      rel: 'icon',
      type: 'image/x-icon',
      href: configData.value.siteFavicon || '/nuxt-icon.png', // Default fallback
    }],
    meta: [
      { property: 'og:type', content: 'website' },
      { property: 'og:site_name', content: configData.value.siteName },
    ],
  });

  const requestConfig2 = handleRequest(configData.value.key);
  const { data: theme } = await useFetch(`${requestConfig2.baseURL}/api/getTheme`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        homepage: configData.value?.theme?.homepage || '',
        header: configData.value?.theme?.header || '',
        subheader: configData.value?.theme?.subheader || '',
        footer: configData.value?.theme?.footer || '',
        dashboard: configData.value?.theme?.dashboard || '',
        checkout: configData.value?.theme?.checkout || '',
      },
    }),
    headers: {
      ...requestConfig2.headers,
      'Content-Type': 'application/json',
    },
  });

  themeData.value = theme.value.data;

  provide('configs', configData.value);
  provide('theme', themeData.value);
  provide('env', isLocalhost.value ? 'localhost' : 'production');

  const { data: space } = await useFetch(`${requestConfig2.baseURL}/api/space/${configData.value.spaceId}`, {
    method: 'GET',
    headers: {
      ...requestConfig2.headers,
      'Content-Type': 'application/json',
    },
  });

  spaceData.value = space.value;

  provide('space', spaceData.value);
  //console.log('spaceData', spaceData.value);

  recordHistory();
  // Detect localhost environment
  if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname.includes('ngrok-free.app')) {
    isLocalhost.value = true;
  }

  if (isLocalhost.value) {
    loadEruda();
  }

  const userCookie = useCookie('_userData', { maxAge: 3600 });
  
  if (userCookie.value) {
    profile.value = JSON.parse(userCookie.value); // โหลดข้อมูลผู้ใช้จาก cookie
    //console.log('Profile loaded from cookie:', profile.value); // ตรวจสอบค่าที่โหลด
    provide('userData', profile.value); // ทำการ provide userdata
  } else {
    const fetchConfig = handleFetch('DU1eYMDG7j8yb199YDPg4', isLocalhost.value ? 'localhost' : 'production');
    //console.log('fetchConfig', fetchConfig);
    const fetchProfile = async () => {
      try {
        const authToken = useCookie('_account');
        const token = authToken.value;
        if (!token) throw new Error('No token found');

        const response = await axios.get(`${fetchConfig.baseURL}/account/profile?key=${fetchConfig.token}`, {
          headers: { Authorization: token },
        });

        if (response.data && response.data.status) {
          profile.value = response.data.profile;
          // Store user data in cookies
          const userCookie = useCookie('_userData', { maxAge: 3600 });
          userCookie.value = JSON.stringify(profile.value); // Save profile data as a JSON string
          provide('userData', profile.value);
        } else {
          throw new Error('Invalid session');
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
      } finally {
        //console.log('profile', profile.value);
      }
    };
    fetchProfile(); // เรียกใช้ fetchProfile ถ้าไม่มีข้อมูลใน cookie
  }
} catch (err) {
  error.value = err;
} finally {
  pending.value = false;
}

onMounted(() => {
  // ตรวจสอบค่า profile
  //console.log('Profile before loading:', profile.value);

  const localhostCookie = useCookie('_localhost', { maxAge: 3600 });
  const cookieValue = localhostCookie.value;
  if (cookieValue && hostsData.value) {
    const optionFromCookie = hostsData.value.find(
      (host) => host.hostname === cookieValue
    );
    if (optionFromCookie) {
      selectedOption.value = optionFromCookie;
    }
  }

  const userCookie = useCookie('_userData', { maxAge: 900 });
  
  if (userCookie.value) {
    if (typeof userCookie.value === 'string') {
      try {
        profile.value = JSON.parse(userCookie.value); // โหลดข้อมูลผู้ใช้จาก cookie
        //console.log('Profile loaded from cookie:', profile.value); // ตรวจสอบค่าที่โหลด
      } catch (error) {
        console.error('Error parsing user cookie:', error);
      }
    } else {
      //console.warn('User cookie is not a string:', userCookie.value);
      profile.value = userCookie.value; // ใช้ค่าที่เป็นวัตถุโดยตรง
    }
  } else {
    const fetchConfig = handleFetch('DU1eYMDG7j8yb199YDPg3', isLocalhost.value ? 'localhost' : 'production');
  //  console.log('fetchConfig', fetchConfig);
    const fetchProfile = async () => {
      try {
        const authToken = useCookie('_account');
        const token = authToken.value;
        if (!token) throw new Error('No token found');

        const response = await axios.get(`${fetchConfig.baseURL}/account/profile?key=${fetchConfig.token}`, {
          headers: { Authorization: token },
        });

        if (response.data && response.data.status) {
          profile.value = response.data.profile;
          // Store user data in cookies
          const userCookie = useCookie('_userData', { maxAge: 3600 });
          userCookie.value = JSON.stringify(profile.value); // Save profile data as a JSON string
        } else {
          throw new Error('Invalid session');
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
      } finally {
        //console.log('profile', profile.value);
      }
    };
    fetchProfile(); // เรียกใช้ fetchProfile ถ้าไม่มีข้อมูลใน cookie
  }

  // ดึงชื่อเบราว์เซอร์และระบบปฏิบัติการ
  const { browserName, osName } = getBrowserAndOS();
  profile.value = { ...profile.value, browserName, osName }; // เพิ่มข้อมูลลงใน profile
  provide('userData', profile.value); // ทำการ provide userdata
});

</script>

<style >

.ProseMirror:focus {
    outline: none;
  }

  .ProseMirror {
    font-family: inherit !important; /* Inherit the font-family from the parent */
  }
  
</style>

<style scoped>

.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full-screen loader */
  font-size: 1.5rem;
  color: #333;
}
a:hover{
  cursor: pointer;
}
/* global.css */
#loading-overlay {
  transition: opacity 0.3s ease;
}

.user-data-columns {
  display: flex;
  justify-content: space-between; /* จัดเรียงคอลัมน์ให้ห่างกัน */
}

.user-data-column {
  flex: 1; /* ให้แต่ละคอลัมน์มีขนาดเท่ากัน */
  text-align: center; /* จัดข้อความให้อยู่กลาง */
}

.toggle-footer-btn {
  position: fixed;
  bottom: 0;
  padding: 5px 10px 5px 10px;
  background-color: #ffffff;
  color: rgb(80, 80, 80);
  border-radius: 7px 7px 0px 0px;
  cursor: pointer;
}

</style>
