// plugins/file.js
import axios from 'axios';
import { useCookie, useNuxtApp } from '#app';
import { useAccessMode } from '~/composables/useAccessMode';

export default defineNuxtPlugin(() => {
  const { $uploadFileToS3, $deleteFileFromS3, $config } = useNuxtApp();
  const { accessMode } = useAccessMode(); // Import accessMode
  const API_KEY = 'DU1eYMDG7j8yb199YDPg3';
  const API_BASE = 'https://gateway.cloudrestfulapi.com/filemanager';
  //const API_BASE = 'http://localhost:5001/filemanager';

  // Retrieve token based on access mode
  const getAuthToken = () => {
    if (accessMode.value === 'public') {
      //console.log("Call in Public");
      return 'oSpHa80H4csU3Zib1FkrGPQw1ZLikf9BBJSXKswsYJytBGR7vmLRkkre14sycehL'; // Public token
    } else {
      //console.log("Call in Private");
      const authToken = useCookie('_account');
      return authToken.value || '';
    }
  };

  // Helper function to prepare file metadata
  const prepareFileMetadata = async (file) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const folder = `filemanager/${year}/${month}`;
    const folderPath = `${year}/${month}`;
    
    const fileSize = file.size;
    const fileType = file.type;
    let dimensions = null;

    // Get image dimensions if the file is an image
    if (fileType.startsWith("image/")) {
      const { width, height } = await new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve({ width: img.width, height: img.height });
        img.src = URL.createObjectURL(file);
      });
      dimensions = `${width}x${height}`;
    }

    return { folder, folderPath, fileSize, fileType, dimensions };
  };

  // In `uploadFile`, include `onProgress` parameter
  // Define the uploadFile function with an onProgress callback parameter
  const uploadFile = async (file, parentFolderId, spaces = $config.public.spaces, onProgress = null) => {
    const token = getAuthToken();
    if (!token) throw new Error('No token found');
    if (!spaces) throw new Error('S3 configuration is not available.');

    try {
      const { folder, folderPath, fileSize, fileType, dimensions } = await prepareFileMetadata(file);
      const timestamp = Date.now();
      const uniqueFileName = `${timestamp}.${file.name.split('.').pop()}`;
      const filePath = `${folderPath}/${uniqueFileName}`;

      // Call uploadFileToS3 with the onProgress callback
      const response = await $uploadFileToS3(new File([file], uniqueFileName), folder, spaces, onProgress);

      const fileUrl = response.fileUrl;

      // Post request to the API with all metadata
      return await axios.post(
        `${API_BASE}/new_file?key=${API_KEY}`,
        {
          name: file.name,
          path: filePath,
          type: 'file',
          parent: parentFolderId,
          url: fileUrl,
          size: fileSize,
          mimetype: fileType,
          dimensions,
          thumbnail: null,
          createdAt: new Date().toISOString(),
        },
        { headers: { Authorization: token } }
      );
    } catch (error) {
      console.error('Error uploading file:', error);
      throw new Error('Failed to upload file');
    }
  };

  const listFiles = async (parentFolderId, spaces = $config.public.spaces) => {
    const token = getAuthToken();
    if (!token) throw new Error('No token found');

    try {
      const response = await axios.post(
        `${API_BASE}/list_parent?key=${API_KEY}`,
        { parent: parentFolderId },
        { headers: { Authorization: token } }
      );
      return response.data || [];
    } catch (error) {
      console.error('Error listing files:', error);
      throw new Error('Failed to list files');
    }
  };

  const deleteFile = async (file, spaces = $config.public.spaces) => {
    if (!file || file.type !== 'file') throw new Error("Invalid file provided for deletion");

    const token = getAuthToken();
    if (!token) throw new Error('No token found');
    if (!spaces) throw new Error('S3 configuration is not available.');

    try {
      // Delete from S3
      await $deleteFileFromS3(file.path, file.folder || 'filemanager', spaces);
      console.log(`File "${file.path}" deleted successfully from S3`);

      // Delete from API
      const apiResponse = await axios.post(
        `${API_BASE}/delete?key=${API_KEY}`,
        { itemId: file._id },
        { headers: { Authorization: token } }
      );

      if (apiResponse.data && apiResponse.data.status) {
        console.log(`File "${file.name}" deleted successfully from API.`);
        return { success: true, message: `File "${file.name}" deleted successfully.` };
      } else {
        console.warn("Unexpected API response during file deletion:", apiResponse);
        return { success: false, message: `File "${file.name}" may not have been deleted from the API.` };
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      return { success: false, message: `Failed to delete file "${file.name}".` };
    }
  };

  const createFolder = async (folderName, parentFolderId, parentName) => {
    const token = getAuthToken();
    if (!token) throw new Error('No token found');

    try {
      const response = await axios.post(
        `${API_BASE}/new_folder?key=${API_KEY}`,
        {
          name: folderName,
          parent: parentFolderId,
          parentName: parentName || 'root'
        },
        {
          headers: { Authorization: token }
        }
      );

      if (response.data && response.data.folderID) {
        return { success: true, message: `Created new folder: ${folderName} under ${parentName || 'Root'}` };
      } else {
        console.warn("Unexpected folder creation response format");
        return { success: false, message: 'Folder creation failed due to an unexpected response.' };
      }
    } catch (error) {
      console.error('Error creating new folder:', error);
      return { success: false, message: 'Failed to create folder' };
    }
  };

  const deleteFolder = async (folderId) => {
    const token = getAuthToken();
    if (!token) throw new Error('No token found');
    
    try {
      const response = await axios.post(
        `${API_BASE}/delete?key=${API_KEY}`,
        {
          itemId: folderId
        },
        {
          headers: { Authorization: token }
        }
      );

      if (response.data.status) {
        return { success: true, message: 'Folder deleted successfully' };
      } else {
        return { success: false, message: `Failed to delete folder: ${response.data.message}` };
      }
    } catch (error) {
      console.error('Error deleting folder:', error);
      return { success: false, message: 'An error occurred while deleting the folder' };
    }
  };

  const rename = async (itemId, newName) => {
    const token = getAuthToken();
    if (!token) throw new Error('No token found');
    
    try {
      const response = await axios.post(
        `${API_BASE}/rename?key=${API_KEY}`,
        {
          itemId,
          newName
        },
        {
          headers: { Authorization: token }
        }
      );

      if (response.data && response.data.status) {
        return { success: true, message: `Renamed to: ${newName}` };
      } else {
        return { success: false, message: 'Rename failed due to an unexpected response format.' };
      }
    } catch (error) {
      console.error('Error renaming item:', error);
      return { success: false, message: 'Failed to rename item' };
    }
  };

  const share = async (itemId, isShare, sharePassword = null, shareExpire = null, isPassword = null, permissions = {}) => {
    const token = getAuthToken();
    if (!token) throw new Error('No token found');
    
    // Log the values being sent in the request payload
    console.log('share function called with:', { itemId, isShare, sharePassword, shareExpire, isPassword, permissions });
    
    try {
        const response = await axios.post(
            `${API_BASE}/share?key=${API_KEY}`,
            {
              itemId,          // Item ID of the item being shared
              isShare,         // Sharing status
              isPassword,      // Optional password protection flag
              sharePassword,   // Password if provided
              shareExpire,     // Expiry date if provided
              permissions      // Permissions object
            },
            {
              headers: { Authorization: token }
            }
        );
  
        // Check the structure of the response
        if (response.data && response.data.status) {
            return {
                success: true,
                message: 'Share options set successfully',
                item: response.data.item
            };
        } else {
            return {
                success: false,
                message: 'Failed to set share options due to an unexpected response format.'
            };
        }
    } catch (error) {
        console.error('Error setting share options:', error);
        return {
            success: false,
            message: 'Failed to set share options'
        };
    }
  };
  
  // Define the external function
  const external = async (shareCode) => {
    if (!shareCode) throw new Error('share_code is required');

    try {
      const response = await axios.get(
        `${API_BASE}/external?key=${API_KEY}`, // Append API key to the base URL
        {
          params: { share_code: shareCode },   // Pass share_code as query parameter
          headers: { Authorization: getAuthToken() } // Set authorization token if required
        }
      );

      // Process response and return success or failure based on the status
      if (response.data && response.data.status) {
        return { success: true, data: response.data.data }; // Data found
      } else {
        return { success: false, message: response.data.message || 'No data found for the provided share_code' };
      }
    } catch (error) {
      console.error('Error fetching external data:', error);
      return { success: false, message: 'Failed to fetch external data' };
    }
  };

  // Method to download file based on share code
  const downloadExternal = async (shareCode) => {
    try {
      // First, get file metadata including filename from /external endpoint
      const metadataResponse = await axios.get(`${API_BASE}/external?key=${API_KEY}`, {
        params: { share_code: shareCode },
        headers: { Authorization: getAuthToken() },
      });

      if (!metadataResponse.data || !metadataResponse.data.data) {
        throw new Error('Failed to retrieve file metadata');
      }

      // Extract the filename from the metadata response
      const filename = metadataResponse.data.data.name || 'download';

      // Now download the file from /download endpoint
      const response = await axios.get(`${API_BASE}/download_external/${shareCode}?key=${API_KEY}`, {
        headers: { Authorization: getAuthToken() },
        responseType: 'blob', // Ensures the response is treated as binary data
      });

      // Create a URL for the downloaded file blob
      const downloadUrl = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = downloadUrl;

      // Set the filename for the download
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the URL after download
      URL.revokeObjectURL(downloadUrl);

      return { success: true, message: 'File downloaded successfully' };
    } catch (error) {
      console.error('Error downloading file:', error);
      return { success: false, message: 'Failed to download file' };
    }
  };

  // Method to download file based on share code
  const downloadFile = async (fileId) => {
    try {
      // First, retrieve document details to get the URL and filename
      const documentResponse = await axios.get(`${API_BASE}/document/${fileId}?key=${API_KEY}`, {
        headers: { Authorization: getAuthToken() },
      });

      if (!documentResponse.data || !documentResponse.data.status) {
        console.error('Document details not found.');
        return { success: false, message: 'Document details not found' };
      }

      const { name } = documentResponse.data.data;

      // Directly download the file from /download endpoint
      const response = await axios.get(`${API_BASE}/download/${fileId}?key=${API_KEY}`, {
        headers: { Authorization: getAuthToken() },
        responseType: 'blob', // Ensures the response is treated as binary data
      });

      const filename = name || 'downloaded_file'; // Customize as needed
      const downloadUrl = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);

      return { success: true, message: 'File downloaded successfully' };
    } catch (error) {
      console.error('Error downloading file:', error);
      return { success: false, message: 'Failed to download file' };
    }
  };
    
  // Search function to search files and folders by name
  const search = async (query) => {
      const token = getAuthToken();
      if (!token) throw new Error('No token found');

      try {
          const response = await axios.post(
          `${API_BASE}/search?key=${API_KEY}`,
          { query },
          { headers: { Authorization: token } }
          );

          if (response.data && response.data.results) {
          return { success: true, results: response.data.results };
          } else {
          console.warn("Unexpected response format from search:", response.data);
          return { success: false, message: 'No results found.' };
          }
      } catch (error) {
          console.error('Error searching files and folders:', error);
          return { success: false, message: 'Failed to perform search' };
      }
  };

  // Method to search files and folders by name under a specific parent ID
  const searchExternal = async (query, fixedParentId) => {
    const token = getAuthToken();
    if (!token) throw new Error('No token found');

    try {
        const response = await axios.post(
            `${API_BASE}/search_external?key=${API_KEY}`,
            { query, fixedParentId },
            { headers: { Authorization: token } }
        );

        if (response.data && response.data.status && response.data.results) {
            return { success: true, results: response.data.results };
        } else {
            console.warn("Unexpected response format from search_external:", response.data);
            return { success: false, message: response.data.message || 'No results found.' };
        }
    } catch (error) {
        console.error('Error searching files and folders externally:', error);
        return { success: false, message: 'Failed to perform external search' };
    }
  };

  // Method to retrieve nested folder structure starting from a specified parent ID
  const FolderStructure = async (parentFolderId) => {
    const token = getAuthToken();
    if (!token) throw new Error('No token found');

    try {
      const response = await axios.get(
        `${API_BASE}/folder_structure?key=${API_KEY}`,
        {
          params: { parent: parentFolderId },
          headers: { Authorization: token }
        }
      );

      if (response.data && response.data.status) {
        return { success: true, structure: response.data.structure };
      } else {
        console.warn("Unexpected response format from folder_structure:", response.data);
        return { success: false, message: 'Failed to retrieve folder structure' };
      }
    } catch (error) {
      console.error('Error retrieving folder structure:', error);
      return { success: false, message: 'An error occurred while retrieving folder structure' };
    }
  };

  const moveFolder = async (itemId, newParentId) => {
    const token = getAuthToken();
    if (!token) throw new Error('No token found');

    try {
      const response = await axios.post(
        `${API_BASE}/move_folder?key=${API_KEY}`,
        {
          itemId,
          newParentId,
        },
        {
          headers: { Authorization: token }
        }
      );

      if (response.data && response.data.status) {
        return { success: true, message: 'Folder moved successfully' };
      } else {
        return { success: false, message: 'Failed to move folder' };
      }
    } catch (error) {
      console.error('Error moving folder:', error);
      return { success: false, message: 'An error occurred while moving the folder' };
    }
  };

  // Provide the plugin under `$file`
  return {
    provide: {
      file: { uploadFile, listFiles, deleteFile, createFolder, deleteFolder, rename, search, share, external, searchExternal, downloadExternal, downloadFile, FolderStructure, moveFolder },
    },
  };
});
