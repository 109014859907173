import { S3, DeleteObjectCommand } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";

export default defineNuxtPlugin((nuxtApp) => {
  // Define the file upload function
  const uploadFileToS3 = async (file, folder = '', configs, onProgress) => {
      try {
          // Ensure configs exist before proceeding
          if (!configs) {
              throw new Error('S3 configuration is not available.');
          }

          // Initialize the S3 client with credentials from configs
          const s3Client = new S3({
              forcePathStyle: false,
              endpoint: configs.s3EndpointDefault,
              region: configs.s3Region,
              credentials: {
                  accessKeyId: configs.s3Key,
                  secretAccessKey: configs.s3Secret,
              },
          });

          // Prepare the file path (folder + filename)
          const filePath = `${folder}/${file.name}`;

          // Use the Upload class to monitor progress
          const parallelUpload = new Upload({
              client: s3Client,
              params: {
                  Bucket: configs.s3Bucket,
                  Key: filePath,
                  Body: file,
                  ACL: 'public-read',
              },
              partSize: 5 * 1024 * 1024, // Set part size to 5MB
              queueSize: 4,              // Concurrent upload parts
          });

          // Attach a progress listener on each upload part
          parallelUpload.on("httpUploadProgress", (progress) => {
            if (progress.loaded && progress.total) {
                // Calculate progress percentage based on current part progress
                const progressPercentage = (progress.loaded / progress.total) * 100;
                // Pass progress to the callback function and log it
                if (onProgress) onProgress(progressPercentage);
            }
          });

          // Wait for the upload to complete
          const uploadResponse = await parallelUpload.done();

          // Construct the file URL using the custom endpoint
          const fileUrl = `${configs.s3Endpoint}${filePath}`;

          // Return the file URL and response
          return { uploadResponse, fileUrl };
      } catch (error) {
          throw new Error(`File upload failed: ${error.message}`);
      }
  };

  // Define the file delete function
  const deleteFileFromS3 = async (filePath, folder = '', configs) => {
    try {
      // Ensure configs exist before proceeding
      if (!configs) {
        throw new Error('S3 configuration is not available.');
      }

      // Initialize the S3 client with credentials from configs
      const s3Client = new S3({
        forcePathStyle: false,
        endpoint: configs.s3EndpointDefault,
        region: configs.s3Region,
        credentials: {
          accessKeyId: configs.s3Key,
          secretAccessKey: configs.s3Secret,
        },
      });

      // Prepare the full path to the file (including folder)
      const fullPath = `${folder}/${filePath}`;

      // Delete the file from S3
      const deleteResponse = await s3Client.send(new DeleteObjectCommand({
        Bucket: configs.s3Bucket,
        Key: fullPath,
      }));

      // Return the delete response
      return { deleteResponse, success: true };
    } catch (error) {
      throw new Error(`File deletion failed: ${error.message}`);
    }
  };

  // Inject the functions into nuxtApp for global usage
  nuxtApp.provide('uploadFileToS3', uploadFileToS3);
  nuxtApp.provide('deleteFileFromS3', deleteFileFromS3);
});
