// composables/useAccessMode.js
import { ref } from 'vue';

const accessMode = ref('private'); // Default to 'private'

export function useAccessMode() {
  const setAccessMode = (mode) => {
    accessMode.value = mode;
  };

  return { accessMode, setAccessMode };
}
