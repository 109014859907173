// plugins/getClientData.js
import axios from "axios";

export default defineNuxtPlugin((nuxtApp) => {
  const getClientData = async () => {
    if (!process.client) {
      return {
        os: "Unknown",
        browser: "Unknown",
        ip: "Unknown",
        location: "Unknown",
        isp: "Unknown",
        screenSize: { width: 0, height: 0 },
      };
    }

    const clientIP = await getClientIp();
    const { location, isp } = await getClientLocationAndISP(clientIP);
    const userAgent = navigator.userAgent;
    const clientOS = getOS(userAgent);
    const clientBrowser = getBrowser(userAgent);
    const screenSize = getScreenSize();

    return {
      os: clientOS,
      browser: clientBrowser,
      ip: clientIP,
      location,
      isp,
      screenSize,
    };
  };

  const getClientIp = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      console.error("Error fetching client IP:", error);
      return "Unknown";
    }
  };

  const getClientLocationAndISP = async (clientIP) => {
    try {
      const response = await axios.get("https://freeipapi.com/api/json/" + clientIP);
      return {
        location: `${response.data.cityName}, ${response.data.regionName}, ${response.data.countryName}`,
        isp: response.data.isProxy ? "Proxy" : "Not a Proxy",
      };
    } catch (error) {
      console.error("Error fetching location and ISP:", error);
      return {
        location: "Unknown",
        isp: "Unknown",
      };
    }
  };

  const getScreenSize = () => {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };

  const getOS = (userAgent) => {
    if (!userAgent) return "Unknown OS";
    let os = "Unknown OS";
    if (userAgent.includes("Win")) {
      os = "Windows";
      if (userAgent.includes("Windows NT 10")) os += " 10";
      else if (userAgent.includes("Windows NT 6.3")) os += " 8.1";
      else if (userAgent.includes("Windows NT 6.2")) os += " 8";
      else if (userAgent.includes("Windows NT 6.1")) os += " 7";
    } else if (userAgent.includes("Mac")) {
      os = "MacOS";
      if (userAgent.includes("Mac OS X 10_15")) os += " Catalina";
      else if (userAgent.includes("Mac OS X 10_14")) os += " Mojave";
      else if (userAgent.includes("Mac OS X 10_13")) os += " High Sierra";
    } else if (userAgent.includes("X11") || userAgent.includes("Linux")) {
      os = "Linux";
    }
    return os;
  };

  const getBrowser = (userAgent) => {
    if (!userAgent) return "Unknown Browser";
    let browser = "Unknown Browser";
    if (userAgent.includes("Chrome")) {
      browser = "Chrome";
      const version = userAgent.match(/Chrome\/(\d+\.\d+\.\d+\.\d+)/);
      return version ? `${browser} ${version[1]}` : browser;
    } else if (userAgent.includes("Firefox")) {
      browser = "Firefox";
      const version = userAgent.match(/Firefox\/(\d+\.\d+)/);
      return version ? `${browser} ${version[1]}` : browser;
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      browser = "Safari";
      const version = userAgent.match(/Version\/(\d+\.\d+\.\d+)/);
      return version ? `${browser} ${version[1]}` : browser;
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident")) {
      browser = "Internet Explorer";
      const version = userAgent.match(/(?:MSIE |rv:)(\d+\.\d+)/);
      return version ? `${browser} ${version[1]}` : browser;
    }
    return browser;
  };

  nuxtApp.provide("getClientData", getClientData);
});
