export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('readmore', {
      mounted(el, binding) {
        const { value } = binding;
        const firstSection = value?.firstSection || '';
        const readMoreSection = value?.readMoreSection || '';
        const linkText = value?.linkText || 'Read More';
        const readLessText = value?.readLessText || 'Read Less';
        const maxHeight = value?.maxHeight || '100px'; // Limit height of first section
  
        // Create a wrapper for the text content
        const wrapper = document.createElement('div');
        wrapper.style.overflow = 'hidden';
        wrapper.style.maxHeight = maxHeight;
        wrapper.innerHTML = firstSection;
  
        // Create the "read more" section and hide it initially
        const readMoreDiv = document.createElement('div');
        readMoreDiv.style.display = 'none';
        readMoreDiv.innerHTML = readMoreSection;
  
        // Create the link element
        const link = document.createElement('a');
        link.innerHTML = linkText;
        link.style.display = 'block';
        link.style.color = 'blue'; // Set link color to blue
        link.style.cursor = 'pointer'; // Make it look clickable
        link.style.marginTop = '10px';
        link.style.textDecoration = 'underline'; // Underline to resemble a traditional link
  
        // Add toggle functionality to the link
        let isExpanded = false;
        link.addEventListener('click', (e) => {
          e.preventDefault(); // Prevent default link behavior
          isExpanded = !isExpanded;
          if (isExpanded) {
            wrapper.style.maxHeight = 'none'; // Expand to show full content
            readMoreDiv.style.display = 'block'; // Show the hidden content
            link.innerHTML = readLessText; // Change link text
          } else {
            wrapper.style.maxHeight = maxHeight; // Collapse the first section
            readMoreDiv.style.display = 'none'; // Hide the extra content
            link.innerHTML = linkText; // Reset link text
          }
        });
  
        // Append elements to the directive element
        el.appendChild(wrapper);
        el.appendChild(readMoreDiv);
        el.appendChild(link);
      },
      updated(el, binding) {
        // Update the content if the binding value changes
        const { value } = binding;
        const firstSection = value?.firstSection || '';
        const readMoreSection = value?.readMoreSection || '';
        const linkText = value?.linkText || 'Read More';
  
        el.querySelector('div').innerHTML = firstSection;
        el.querySelector('div + div').innerHTML = readMoreSection;
        el.querySelector('a').innerHTML = linkText;
      }
    });
  });
  