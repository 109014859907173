import CryptoJS from 'crypto-js';
import debug from '~/utils/logger.js'; // Adjust path as needed

// Base URLs for different environments
const environments = {
  localhost: 'http://localhost:5001',
  production: 'https://gateway.cloudrestfulapi.com',
};

// Helper function to generate the token and necessary headers
function generateToken(key) {
  const secret = CryptoJS.lib.WordArray.random(128 / 8);
  const iv = CryptoJS.lib.WordArray.random(128 / 8);
  const hashingArray = CryptoJS.enc.Utf8.parse('dF5NQqK4lBpncFdVNBwzEnJz8hWgEUEH'); // Replace with your hashing logic
  const timestamp = Date.now();
  const keyWithTimestamp = { key, timestamp };
  const combinedKey = CryptoJS.lib.WordArray.create()
    .concat(secret)
    .concat(hashingArray);
  const token = CryptoJS.AES.encrypt(JSON.stringify(keyWithTimestamp), combinedKey, {
    iv: iv,
  }).toString();

  return { token, secret, iv, timestamp };
}

// Function to generate the necessary headers
export function generateHeaders2(key) {
  const { token, secret, iv } = generateToken(key);

  return {
    'Content-Type': 'application/json', // Add Content-Type header
    'client-token-key': key,
    'x-content-token': token,
    'x-content-sign': iv.toString(),
    'x-content-key': secret.toString(),
  };
}

// Function to handle resource output, including the base URL and headers
export function handleFetch(key, env = 'production', method = 'GET', data = null) {
  // Validate environment
  if (!environments[env]) {
    throw new Error(`Invalid environment: ${env}`);
  }

  const baseURL = environments[env];
  const headers = generateHeaders2(key);
  const token   = key;

  return {
    baseURL,
    method,
    headers,
    token,
    ...(data && { body: JSON.stringify(data) }), // Include body if data is passed
  };
}