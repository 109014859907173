export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('scrollbar', {
      // This hook is called when the directive is bound to an element
      mounted(el, binding) {
        const { value } = binding;
  
        // Define width and height of the scrollable container (default to 100%)
        const rootWidth = value?.width || '100%';
        const rootHeight = value?.height || '100%';
        const rootPadding = value?.padding || '10px'; // Default padding
  
        // Apply styles to the element to make it scrollable
        el.style.width = rootWidth;
        el.style.height = rootHeight;
        el.style.overflowY = 'scroll'; // Always show vertical scrollbar
        el.style.overflowX = 'hidden'; // Hide horizontal scrollbar
        el.style.boxSizing = 'border-box'; // Ensures proper sizing
        el.style.padding = rootPadding;
        
  
        // Add custom scrollbar styles for WebKit browsers (Chrome, Safari, Edge)
        const scrollbarStyle = document.createElement('style');
        scrollbarStyle.innerHTML = `
          ::-webkit-scrollbar {
            width: 8px;
          }
  
          ::-webkit-scrollbar-thumb {
            background-color: #888;
          }
  
          ::-webkit-scrollbar-track {
            background-color: #f0f0f0;
          }
        `;
  
        // Append the custom styles to the element
        el.appendChild(scrollbarStyle);
      },
      updated(el, binding) {
        // Update the styles if the binding value changes
        const { value } = binding;
        const rootWidth = value?.width || '100%';
        const rootHeight = value?.height || '100%';
        const rootPadding = value?.padding || '10px'; // Default padding
  
        el.style.width = rootWidth;
        el.style.height = rootHeight;
        el.style.padding = rootPadding;
      },
    });
  });
  