<template>
    <div class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="bg-white p-6 rounded-md shadow-md w-full max-w-lg">
        <h2 class="text-xl font-bold mb-4">{{ title }}</h2>
        <p class="text-gray-600 mb-4">{{ message }}</p>
        <!-- Conditionally render the input field -->
        <input v-if="useInput" v-model="inputValue" type="text" class="w-full p-2 mb-4 border rounded-md" />
        <div class="flex justify-end space-x-4">
          <button v-if="showCancel" @click="handleCancel" class="px-4 py-2 bg-gray-300 rounded-md">
            {{ cancelButtonText }}
          </button>
          <button @click="handleConfirm" class="px-4 py-2 bg-blue-500 text-white rounded-md">
            {{ confirmButtonText }}
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  
  // Props from parent
  const props = defineProps({
    message: { type: String, required: true },
    title: { type: String, default: 'Prompt' },
    confirmButtonText: { type: String, default: 'OK' },
    cancelButtonText: { type: String, default: 'Cancel' },
    showCancel: { type: Boolean, default: true },
    useInput: { type: Boolean, default: true } // Control for showing the input
  });
  
  // Local state
  const inputValue = ref('');
  
  // Emits to parent
  const emit = defineEmits(['confirm', 'cancel']);
  
  // Handlers
  const handleConfirm = () => {
    if (props.useInput) {
      emit('confirm', inputValue.value); // Emit the input value if useInput is true
    } else {
      emit('confirm', true); // Emit true for confirmation without input
    }
  };
  
  const handleCancel = () => {
    emit('cancel');
  };
  </script>
  