import { ref } from 'vue';
import checkAuthToken from '~/middleware/auth'; // Import your session validation logic

// Define a shared state for session validity
const sessionValid = ref(false);

// Function to check and update session status
export function useSession() {
  // Function to check session validity
  const refreshSession = async () => {
    sessionValid.value = await checkAuthToken();
  };

  return {
    sessionValid,
    refreshSession,
  };
}
