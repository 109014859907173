import { useSession } from '~/composables/useSession'; // Import session management composable
import { watch } from 'vue'; // Import watch from Vue

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    const idleMinutes = 120; // Enter the number of minutes (e.g., 1 for 1 minute)
    const idleTimeout = idleMinutes * 60 * 1000; // Convert minutes to milliseconds

    let idleTimer;
    let intervalTimer;

    const { sessionValid } = useSession(); // Use session validation state
    const authToken = useCookie('_account'); // Use Nuxt's useCookie composable

    // Function to reset the idle timer
    const resetIdleTimer = () => {
      if (idleTimer) clearTimeout(idleTimer);
      if (intervalTimer) clearInterval(intervalTimer);

      //console.log(`Idle check started. Waiting for user inactivity... (${idleMinutes} minutes)`);

      let remainingTime = idleTimeout;
      const intervalTime = idleTimeout / 10; // 10% of idleTimeout

      // Log every 10% of the remaining time
      intervalTimer = setInterval(() => {
        remainingTime -= intervalTime;
        //console.log(`Remaining time: ${(remainingTime / 1000).toFixed(1)} seconds`);

        if (remainingTime <= 0) {
          clearInterval(intervalTimer);
        }
      }, intervalTime);

      idleTimer = setTimeout(() => {
        //console.log('Timeout reached. Removing auth token before session is marked as invalid.');

        // Remove the auth token first
        if (authToken.value) {
          authToken.value = null; // Remove the cookie
          console.log('Auth token removed.');
        }

        // Directly mark session as invalid after removing the cookie
        sessionValid.value = false;
        console.log('Session marked as invalid.');

        // Redirect to homepage
        nuxtApp.$router.push('/'); // Redirect to homepage
      }, idleTimeout);
    };

    // Function to stop the idle timer and clear intervals
    const stopIdleTimer = () => {
      if (idleTimer) clearTimeout(idleTimer);
      if (intervalTimer) clearInterval(intervalTimer);
      //console.log('Idle timer stopped.');
    };

    // Watch for changes to sessionValid, and start/stop idle detection based on sessionValid
    watch(sessionValid, (newValue) => {
      if (newValue === true) {
        //console.log('Session is valid. Starting idle check...');
        resetIdleTimer(); // Start the idle timer if session is valid
      } else {
        //console.log('Session is invalid or ended. Stopping idle check.');
        stopIdleTimer(); // Stop the idle timer if session becomes invalid
      }
    });

    // Listen for user activity and reset idle timer if session is valid
    const activityEvents = ['mousemove', 'keydown', 'mousedown', 'touchstart'];

    activityEvents.forEach(event => {
      window.addEventListener(event, () => {
        if (sessionValid.value) {
          resetIdleTimer();
        }
      });
    });
  }
});
