// margin.js

import { sizeMap } from './sizeMap.js';

// Convert Tailwind margin object to corresponding CSS values
export function margin(marginObject) {
  if (typeof marginObject !== 'object' || !marginObject) {
    return {}; // Return an empty object if the input is not valid
  }

  const marginStyles = {};
  
  // Handle each side individually
  if (marginObject.left) {
    marginStyles.marginLeft = sizeMap[marginObject.left] || '0px';
  }
  if (marginObject.right) {
    marginStyles.marginRight = sizeMap[marginObject.right] || '0px';
  }
  if (marginObject.top) {
    marginStyles.marginTop = sizeMap[marginObject.top] || '0px';
  }
  if (marginObject.bottom) {
    marginStyles.marginBottom = sizeMap[marginObject.bottom] || '0px';
  }

  return marginStyles;
}
