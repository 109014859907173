import CryptoJS from 'crypto-js';

class StorageManager {
  constructor(storageType = 'localStorage') {
    this.useEncryption = false; // Default to using encryption
    this.secretKey = 'GZp5+YcJml0SW5JFQVZ9TQ==';
    this.storage = this.getStorageInstance(storageType);
  }

  get(name, key = null) {
    const item = this.storage.getItem(name);
    return this.retrieveItem(item, key);
  }

  set(name, item) {
    this.storeItem(name, item);
  }

  update(name, newItem) {
    const existingItem = this.get(name);
    if (existingItem) {
      const mergedItem = { ...existingItem, ...newItem };
      this.set(name, mergedItem);
    } else {
      this.set(name, newItem);
    }
  }

  delete(name) {
    this.storage.removeItem(name);
  }

  encrypt(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), this.secretKey).toString();
  }

  decrypt(ciphertext) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, this.secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  setEncryption(enabled) {
    this.useEncryption = enabled;
  }

  getStorageInstance(storageType) {
    if (typeof window !== 'undefined') {
      if (storageType === 'localStorage') {
        return window.localStorage;
      } else if (storageType === 'sessionStorage') {
        return window.sessionStorage;
      } else {
        throw new Error('Invalid storage type');
      }
    } else {
      throw new Error('Storage is not available in this environment');
    }
  }

  retrieveItem(item, key) {
    if (!item) {
      return null;
    }
  
    let data;
  
    // Try to parse the item as JSON
    try {
      data = this.useEncryption ? this.decrypt(item) : JSON.parse(item);
    } catch (e) {
      // If parsing fails, assume it's a plain string
      data = item;
    }
  
    if (key) {
      return typeof data === 'object' ? data[key] : null;
    }
  
    return data;
  }
  

  storeItem(name, item) {
    const data = this.serializeItem(item);
    this.storage.setItem(name, data);
  }

  serializeItem(item) {
    return this.useEncryption ? this.encrypt(item) : JSON.stringify(item);
  }
}

let storageManager = null;

if (typeof window !== 'undefined') {
  storageManager = new StorageManager('localStorage');
}

export default storageManager;
