// width.js

// Define a mapping of width classes to CSS values
const widthMap = {
    'max-w-full': '100%',
    'max-w-7xl': '80rem', // 1280px
    'max-w-6xl': '72rem', // 1152px
    'max-w-5xl': '64rem', // 1024px
    'max-w-4xl': '56rem', // 896px
    'max-w-3xl': '48rem', // 768px
    'max-w-2xl': '42rem', // 672px
    'max-w-xl': '36rem', // 576px
  };
  
  // Function to get width value based on the input class
  export function width(widthClass) {
    return widthMap[widthClass] || '100%'; // Default to '100%' if the width class is not found
  }
  