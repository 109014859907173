export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('lightbox', {
      mounted(el, binding) {
        const {
          thumbnailSrc = '',  // Source of the thumbnail image
          fullSrc = '',       // Source of the full-size image
          alt = el.alt || 'Image',  // Use the element's alt attribute if provided
          caption = '',       // Caption text to show in lightbox (fallback to alt if not provided)
          lightboxOptions = {}, // Additional options for customization (e.g., width, height)
        } = binding.value || {};
  
        // Apply default styles to the image
        el.style.cursor = 'pointer';
        el.style.maxWidth = lightboxOptions.width || '300px'; // Default max-width or user-defined
        el.style.height = 'auto';
  
        // Create lightbox modal structure
        const createLightboxModal = () => {
          const lightboxOverlay = document.createElement('div');
          lightboxOverlay.style.position = 'fixed';
          lightboxOverlay.style.top = '0';
          lightboxOverlay.style.left = '0';
          lightboxOverlay.style.width = '100%';
          lightboxOverlay.style.height = '100%';
          lightboxOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
          lightboxOverlay.style.display = 'flex';
          lightboxOverlay.style.alignItems = 'center';
          lightboxOverlay.style.justifyContent = 'center';
          lightboxOverlay.style.flexDirection = 'column'; // Make content stacked vertically
          lightboxOverlay.style.zIndex = '9999'; // Ensure it's on top of other elements
  
          // Create image element to be shown in lightbox
          const lightboxImage = document.createElement('img');
          lightboxImage.src = fullSrc || thumbnailSrc || el.src;  // Use fullSrc if available, fallback to thumbnail
          lightboxImage.alt = alt;
          lightboxImage.style.maxWidth = lightboxOptions.width || '80%'; // Customizable width
          lightboxImage.style.maxHeight = lightboxOptions.height || '80%'; // Customizable height
          lightboxImage.style.border = '5px solid white';
          lightboxImage.style.borderRadius = '0px';
          lightboxImage.style.boxShadow = '0 0 10px rgba(255, 255, 255, 0.5)';
  
          // Create caption element for the image
          const lightboxCaption = document.createElement('div');
          lightboxCaption.innerText = caption || el.alt || ''; // Add caption or fallback to alt text if provided
          lightboxCaption.style.marginTop = '10px';
          lightboxCaption.style.backgroundColor = 'rgba(0, 0, 0, 0.7)'; // Black background with opacity
          lightboxCaption.style.color = '#fff';
          lightboxCaption.style.padding = '10px 15px';
          lightboxCaption.style.borderRadius = '0px';
          lightboxCaption.style.fontSize = '14px';
          lightboxCaption.style.textAlign = 'center';
          lightboxCaption.style.maxWidth = '90%';
          lightboxCaption.style.width = 'fit-content';
  
          // Append image and caption to overlay
          lightboxOverlay.appendChild(lightboxImage);
          if (caption || el.alt) {
            lightboxOverlay.appendChild(lightboxCaption);
          }
  
          // Close lightbox on click
          lightboxOverlay.addEventListener('click', () => {
            lightboxOverlay.remove();
          });
  
          // Append the overlay to the body
          document.body.appendChild(lightboxOverlay);
        };
  
        // Add click event to the element
        el.addEventListener('click', () => {
          createLightboxModal();
        });
      },
    });
  });
  