export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('tooltip', {
      mounted(el, binding) {
        // Create a tooltip element
        const tooltip = document.createElement('div');
        tooltip.className = 'nuxt-tooltip';
        tooltip.style.position = 'absolute';
        tooltip.style.padding = '5px';
        tooltip.style.backgroundColor = 'black';
        tooltip.style.color = 'white';
        tooltip.style.borderRadius = '4px';
        tooltip.style.fontSize = '12px';
        tooltip.style.whiteSpace = 'nowrap';
        tooltip.style.visibility = 'hidden';
        tooltip.style.transition = 'opacity 0.2s';
        tooltip.style.zIndex = '999999'; // Add z-index
  
        // Set the tooltip content
        tooltip.textContent = binding.value;
        document.body.appendChild(tooltip);
  
        // Show tooltip on mouseenter
        el.addEventListener('mouseenter', (event) => {
          tooltip.style.visibility = 'visible';
          tooltip.style.opacity = '1';
          tooltip.style.top = `${event.pageY + 5}px`;
          tooltip.style.left = `${event.pageX + 5}px`;
        });
  
        // Hide tooltip on mouseleave
        el.addEventListener('mouseleave', () => {
          tooltip.style.visibility = 'hidden';
          tooltip.style.opacity = '0';
        });
  
        // Update tooltip position on mousemove
        el.addEventListener('mousemove', (event) => {
          tooltip.style.top = `${event.pageY + 5}px`;
          tooltip.style.left = `${event.pageX + 5}px`;
        });
      },
      unmounted() {
        // Cleanup tooltip element when the directive is unmounted
        document.querySelector('.nuxt-tooltip')?.remove();
      },
    });
  });
  