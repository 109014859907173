
<template>
  <div>
    <!-- Button to Toggle Panel -->
    <button
      v-if="isLocalhost"
      @click="$emit('toggle-panel')"
      class="fixed top-0 left-4 bg-gray-900 text-gray-300 px-3 py-1 rounded-b-lg shadow-md z-50 text-sm border-b border-l border-r border-gray-700"
      :class="isPanelVisible ? 'mt-[38px]' : 'mt-0'"
    >
      <span v-if="isPanelVisible"><i class="fas fa-caret-up mr-1"></i> ปิดหน้าต่าง</span>
      <span v-else><i class="fas fa-caret-down mr-1"></i> เปิดหน้าต่าง</span>
    </button>

    <!-- Sliding Panel -->
    <div
      class="fixed top-0 left-0 right-0 bg-gray-900 text-gray-300 shadow-lg z-40 border-b border-gray-700"
      :class="isPanelVisible ? 'translate-y-0' : '-translate-y-full'"
    >
      <div class="p-1 flex justify-between items-center">
        <span class="text-sm">
          <i class="fas fa-globe"></i> <span class="font-bold">โฮสต์ปัจจุบัน : </span>
          <span class="text-gray-400">{{ hostname }}</span>
        </span>

        <!-- Dropdown Section -->
        <div class="relative w-full max-w-lg flex justify-end">
          <!-- Dropdown Button -->
          <button
            @click="$emit('toggle-dropdown')"
            class="text-md bg-gray-800 border border-gray-600 rounded px-2 py-1 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 text-right"
          >
            <span v-if="selectedOption">
              <span class="block text-sm text-gray-400">
                <i class="fas fa-caret-down mr-1"></i> {{ selectedOption.hostname }}
              </span>
            </span>
            <span v-else><i class="fas fa-globe"></i> เลือกโฮสต์</span>
          </button>

          <!-- Remove Button -->
          <button
            @click="$emit('reset-host')"
            v-if="selectedOption"
            class="text-sm bg-red-600 border border-red-500 rounded px-2 py-1 text-white ml-2 focus:outline-none focus:ring-2 focus:ring-red-400"
          >
            <i class="fas fa-times"></i> รีเซ็ต
          </button>

          <!-- Dropdown Options with Search Bar -->
          <div
            v-if="dropdownVisible && isPanelVisible"
            class="absolute bg-gray-900 border border-gray-700 rounded mt-10 shadow-lg z-50 p-3"
            style="min-width: 300px; max-width: 600px; right: 0;"
          >
            <!-- Search Bar -->
            <div class="flex justify-between items-center mb-3">
                <input
                :value="searchQuery"
                @input="$emit('update:searchQuery', $event.target.value)"
                type="text"
                placeholder="ค้นหา..."
                class="w-full px-2 py-1 text-sm text-black rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />              
                <!-- Close Button -->
                <button
                    @click="$emit('toggle-dropdown')"
                    class="ml-2 text-sm bg-red-600 text-white px-2 py-1 rounded hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400 flex items-center"
                >
                    <i class="fas fa-times mr-1 text-sm"></i> ปิด
                </button>
            </div>

            <!-- Filtered Options -->
            <div class="grid grid-cols-2 gap-2">
              <div
                v-for="option in filteredHosts"
                :key="option.hostname"
                :class="{
                  'px-3 py-2 bg-gray-800 hover:bg-gray-700 cursor-pointer rounded shadow-sm': option.hostname !== selectedOption?.hostname,
                  'px-3 py-2 bg-gray-600 cursor-not-allowed rounded shadow-sm': option.hostname === selectedOption?.hostname,
                }"
                @click="option.hostname !== selectedOption?.hostname && $emit('select-option', option)"
              >
                <span
                  class="block text-sm font-semibold"
                  :class="{
                    'text-gray-300': option.hostname !== selectedOption?.hostname,
                    'text-gray-500': option.hostname === selectedOption?.hostname,
                  }"
                >
                  {{ option.siteName }}
                </span>
                <span
                  class="block text-xs"
                  :class="{
                    'text-gray-400': option.hostname !== selectedOption?.hostname,
                    'text-gray-500': option.hostname === selectedOption?.hostname,
                  }"
                >
                  {{ option.hostname }}
                </span>
              </div>
            </div>

            <!-- Total Host Count -->
            <div class="mt-4 text-center text-gray-300 text-sm">
              โฮสต์ทั้งหมด: {{ totalHosts }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

// Props
const props = defineProps({
  isLocalhost: Boolean,
  isPanelVisible: Boolean,
  dropdownVisible: Boolean,
  selectedOption: Object,
  hostname: String,
  hostsData: Array,
  searchQuery: String,
});

// Computed properties
const filteredHosts = computed(() => {
  if (!props.searchQuery) {
    return props.hostsData.slice(-14);
  }
  return props.hostsData.filter((option) =>
    option.siteName.toLowerCase().includes(props.searchQuery.toLowerCase()) ||
    option.hostname.toLowerCase().includes(props.searchQuery.toLowerCase())
  );
});

const totalHosts = computed(() => props.hostsData.length);
</script>