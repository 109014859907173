import { h, render } from 'vue';
import CustomAlert from '~/components/plugin/CustomAlert.vue';
import CustomPrompt from '~/components/plugin/CustomPrompt.vue';
import CustomToast from '~/components/plugin/CustomToast.vue';

export default defineNuxtPlugin((nuxtApp) => {
  let toastContainer;

  const customAlertPrompt = (type, options = {}) => {
    const {
      message = '',
      title = '',
      confirmButtonText = 'OK',
      cancelButtonText = 'Cancel',
      showCancel = true,
      duration = 3000,
      useInput = true // Added useInput option here, default is true
    } = options;

    return new Promise((resolve) => {
      if (type === 'toast') {
        if (!toastContainer) {
          toastContainer = document.createElement('div');
          toastContainer.className = 'fixed bottom-5 right-5 space-y-2 z-50';
          document.body.appendChild(toastContainer);
        }

        const toastElement = document.createElement('div');
        toastContainer.appendChild(toastElement);

        const cleanup = () => {
          render(null, toastElement);
          if (toastElement.parentNode) {
            toastElement.parentNode.removeChild(toastElement);
          }
        };

        render(
          h(CustomToast, {
            message,
            duration,
            onClose: cleanup,
          }),
          toastElement
        );

        setTimeout(() => {
          cleanup();
          resolve(true);
        }, duration);
      } else {
        const container = document.createElement('div');
        document.body.appendChild(container);

        const cleanup = () => {
          if (container && container.parentNode) {
            render(null, container);
            container.parentNode.removeChild(container);
          }
        };

        if (type === 'alert') {
          const handleConfirm = () => {
            resolve(true);
            cleanup();
          };

          render(
            h(CustomAlert, {
              message,
              title,
              confirmButtonText,
              onConfirm: handleConfirm,
            }),
            container
          );
        }

        if (type === 'prompt') {
          const handleConfirm = (inputValue) => {
            resolve(inputValue);
            cleanup();
          };
        
          const handleCancel = () => {
            resolve(null);
            cleanup();
          };
        
          render(
            h(CustomPrompt, {
              message,
              title,
              confirmButtonText,
              cancelButtonText,
              showCancel,
              useInput, // Pass useInput to control whether input is shown or not
              onConfirm: handleConfirm,
              onCancel: handleCancel,
            }),
            container
          );
        }
        
      }
    });
  };

  nuxtApp.provide('alertPrompt', customAlertPrompt);
});
